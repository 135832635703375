import { isEmpty } from './strings';
import moment from "moment";

/**
 * Checks that the date is set for the 1st and 15th
 *
 * @param {JSON} props - The state of the calling component should contain
 *                       a date entry with year, month and day passed in
 */
export const checkDate = (props, roleName ='', role = '', overall, accessRoles = []) => {
    let findedRole = false;
    if (accessRoles && accessRoles.length > 0) {
      findedRole = accessRoles.includes(roleName);
    }
    if (!props.date.value) {
        return {
            ...props.date,
            error: true,
            errorMessage: 'Please input a valid date.',
        };
    }
    const today = new Date();
    const maxDate = new Date();
    let errorMessageForMaxDate = 'Effective Date must be within a 90 day range from today'

    if (overall.platform === 'PRIME' && overall.uhcStateConfig) {
        let offSet = role === 'Internal' ? overall.uhcStateConfig.internalOffset : overall.uhcStateConfig.externalOffset;
        maxDate.setDate(maxDate.getDate() + offSet);
        errorMessageForMaxDate = `Effective Date must be within a ${offSet} day range from today`;
    } else {
        let maxDateOffset = 90;
        if (findedRole) {
            maxDateOffset = 130;
        } else {
            if (role === "Internal") {
                maxDateOffset = 90;
            } else {
                maxDateOffset = 60;
            }
        }
        maxDate.setDate(maxDate.getDate() + maxDateOffset);
        errorMessageForMaxDate = `Effective Date must be within a ${maxDateOffset} day range from today`;
    }

    const date = new Date(props.date.year, props.date.month - 1, props.date.day);

    if (date > maxDate) {
        return {
            ...props.date,
            error: true,
            errorMessage: errorMessageForMaxDate
        };
    } if (process.env.REACT_APP_SPECIALTY_ONLY === 'true' && props.date.month < 10 && props.date.year === 2020) {
        return {
            ...props.date,
            error: true,
            errorMessage: 'Effective Date must be after 10/01/2020',
        };
    }
    return {
        ...props.date,
        error: false,
    };
};

/**
 * Sets the values for a date object using the event
 *
 * @param {Text} event - A date. ex. Fri Nov 01 2019 00:00:00 GMT-0400
 * @param {JSON} props - Part of the state or props of the calling component
 *                       containing date info to be copied if there was an event
 */
export const setDate = (event, props) => {
    if (!event) {
        return null;
    }
    if (typeof event === "String"){
        if (event.includes("_")) {
            return null;
        }
    }
    const value = event;
    const month = value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : `${value.getMonth() + 1}`;
    const day = value.getDate() < 10 ? `0${value.getDate()}` : `${value.getDate()}`;
    const year = value.getFullYear();
    const yearMonthDay = `${year}-${month}-${day}`;
    const monthDayYear = `${month}/${day}/${year}`
    return {
        date: {
            ...props,
            value,
            month,
            year,
            day,
            yearMonthDay,
            monthDayYear
        },
    };
};

/**
 * Clears the date value from the date object sent in
 * @param {JSON} props - A date object sent in from the calling component that will be updated
 */
export const clearDate = (props) => ({
    date: {
        ...props,
        value: '',
        fieldActive: false,
    },
});

/**
 * Reformats the date from the date object to a more readable format
 * @param {string} inputDate - A date string in the format YYYY-MM-DD
 */
export const formatDate = (inputDate) => {
    if (isEmpty(inputDate)) {
        return inputDate;
    }
    const splitDate = inputDate.split('-');
    if (splitDate.length === 0) {
        return inputDate;
    }

    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
};
