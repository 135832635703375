import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { getTransactionResult } from './quickQuotesActions';
import { getRatingCount } from './companyProfileActions';
import { getPlanCodeBatches } from './fullQuotesActions';
import { auditUserAction } from './actionHelpers/createHistoryHelper';
import deepCopy from '../utils/deepCopy';
import { removeWaivedAllEmployees } from '../components/activities/Enrollment/EmployeeEnroll/helpers/employeeRateHelper';
import { UHC_BENEFIT_RIDER_RATE_ERROR, UHC_BENEFIT_RIDER_SELECTED_RATE_ERROR, KEY_NAME } from '../utils/errorLogging/logKeys';
import { logError } from '../utils/errorLogging/logError';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { doStopRateCalls } from './actionHelpers/fullQuoteActionHelper';
import { getMarketType, MARKET_TYPES, isUhcRenewals } from '../utils/businessLogic/platformUtils';

const apiRoutes = require('../utils/api/apiRouteConfig');
const RATE_API_ENDPOINT = '/rates/startRates';

function callRateApi(reqBody, dispatch, dispatchActionType) {
    return new Promise((resolve, reject) => {
        axios.post(`${apiRoutes.quickQuotes}${RATE_API_ENDPOINT}`, reqBody)
            .then(async (res) => {
                const results = await getTransactionResult(res.data.transactionId);
                if (results.statusCode === 400) {
                    throw await results;
                }
                const rates = results.medicalRates;
                if (rates && !doStopRateCalls()) {
                    dispatch({
                        type: dispatchActionType,
                        rates
                    });
                }
                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.SERVICE['FI'].ERROR_QUICK_QUOTE_RATES,
                    payload: formatPlanOrRateCallError(err, 'rate', false)
                });
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                logError(KEY_NAME, UHC_BENEFIT_RIDER_RATE_ERROR, 'callRateApi', reqBody, err);
                reject(err);
            });
    });
}

/** Function to bring benefit rider rates in Quick Quotes after normal rate call is complete on plan selection page*/
export function getAllRidersRatesQQ(ratesRequest, index, medicalPlans, groupSize) {
    return (dispatch) => {

        // If it's first rate batch call then dispatching the REQUEST_BENEFIT_RIDER_RATES action
        // to set numBenefitRidersRatesFetched and allBenefitRidersRatesFetched to 0 in state
        if (index === 0) {
            dispatch({ type: types.REQUEST_BENEFIT_RIDER_RATES });
        }
        if(ratesRequest.sicCode !== null && ratesRequest.sicCode !== undefined && typeof ratesRequest.sicCode === "number"){
            ratesRequest.sicCode = ratesRequest.sicCode.toString();
        }

        const requestBody = {
            groupSize: groupSize,
            zipCode: ratesRequest.zipCode,
            stateCode: ratesRequest.stateCode,
            countyCode: ratesRequest.countyFIPSCode,
            effectiveDate: ratesRequest.effectiveDate,
            platform: ratesRequest.platform,
            sicCode: ratesRequest.sicCode,
            medicalPlanCodes: ratesRequest.planCodes,
            selectedOptionalRiders: getAllPlansRiders(ratesRequest.planCodes, medicalPlans),
            OISABit: ratesRequest.OISABit,
            ratingCount: groupSize.toString(),
        }
        return callRateApi(requestBody, dispatch, types.RECEIVE_BENEFIT_RIDER_RATES);
    };
}

/** Function to bring benefit rider rates in Full Shopping after normal rate call is complete on plan selection page. */
export function getAllRidersRatesFS(medicalPlanCodes, index, OISABit = {}, simpleCensus = null) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const selectedOptionalRiders = getAllPlansRiders(medicalPlanCodes, [], fullProps);
        const requestBody = getRequestBody(fullProps, medicalPlanCodes, OISABit, selectedOptionalRiders, simpleCensus);
        if (index === 0) {
            dispatch({ type: types.REQUEST_BENEFIT_RIDER_RATES });
        }
        return callRateApi(requestBody, dispatch, types.RECEIVE_BENEFIT_RIDER_RATES);
    };
}

/** Function to bring rates of plans with selected benefit rider in batches. Called on Save button click in benefit rider modal. */
export function getRatesWithSelectedRiders(selectedOptionalRiders) {
    return (dispatch, getState) => {

        const planCodes = Object.keys(selectedOptionalRiders);
        const batchOISABit = [];
        const planCodeBatches = getPlanCodeBatches(getState(), planCodes, batchOISABit);

        dispatch({
            type: types.SAVE_SELECTED_RIDERS_IN_PLANS,
            selectedBenefitRiders: selectedOptionalRiders
        })
        dispatch({
            type: types.REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS,
            numRatesToBeFetched: planCodes.length
        })

        //US3384133 - Implement Audit Log in UI Project
        dispatch(auditUserAction(`Benefit Rider(s)`, `${Object.values(selectedOptionalRiders)}`, `selected`));

        dispatch(requestRateBatchWithSelectedRiders(planCodeBatches[0], batchOISABit[0], selectedOptionalRiders))
            .then(() => {
                for (let i = 1; i < Object.keys(planCodeBatches).length; i++) {
                    dispatch(requestRateBatchWithSelectedRiders(planCodeBatches[i], batchOISABit[i], selectedOptionalRiders))
                }
            })
            .catch((err) => {
                console.log(err);
                logError(KEY_NAME, UHC_BENEFIT_RIDER_SELECTED_RATE_ERROR, 'getRatesWithSelectedRiders', planCodeBatches, err);
            });
    };
}

/** Called on plan deselection if plan had benefit rider selected to bring previous rates back */
export function requestOldRatesBatch(planCodes, OISABit) {
    return (dispatch, getState) => {
        const requestBody = getRequestBody(getState(), planCodes, OISABit, {});
        return callRateApi(requestBody, dispatch, types.RESTORE_PREMIUM_ON_DESELECT);
    };
}



// ------------------------------- HELPER METHODS -------------------------------------------------------------// 


/** Function to create request body for the medical rate call . */
const getRequestBody = (fullProps, medicalPlanCodes, OISABit = {}, selectedOptionalRiders = {}, simpleCensus = null) => {

    const { companyProfile, overall: { source, platform }, quotes: { quickQuoteRequest } } = fullProps;
    const { primaryLocationIdx, employees, locations, companyName, franchiseCode } = companyProfile;
    const { groupSize, effectiveDate } = quickQuoteRequest;
    const { zipCode, stateCode, countyFIPSCode: countyCode } = quickQuoteRequest.zipObj;
    const { sicCode } = quickQuoteRequest.otherDetails;

    let staticRequestBody = {
        groupSize,
        zipCode,
        stateCode,
        countyCode,
        effectiveDate,
        platform,
        sicCode,
        medicalPlanCodes,
        OISABit,
        ratingCount: groupSize.toString(),
        selectedOptionalRiders,
    }

    // Full quote body
    if (source === 'full') {
        staticRequestBody = {
            ...staticRequestBody,
            companyName: companyName,
            franchiseCode: franchiseCode ? franchiseCode : '',
            employees: getEmployees(employees, primaryLocationIdx, stateCode),
            location: getLocation(locations, primaryLocationIdx),
            ratingCount: getRatingCount(locations, primaryLocationIdx),
        }
    }

    if (simpleCensus) {
        delete staticRequestBody.employees;
        staticRequestBody.simpleCensus = simpleCensus;
    }

    return staticRequestBody;
}

/** Batch call to bring plans with selected benefit riders.  */
const requestRateBatchWithSelectedRiders = (planCodes, OISABit, selectedOptionalRiders) => {
    return (dispatch, getState) => {
        const requestBody = getRequestBody(getState(), planCodes, OISABit, selectedOptionalRiders)
        return callRateApi(requestBody, dispatch, types.RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS);
    };
}


export const getAllPlansRiders = (planCodes, medicalPlans = [], fullProps) => {

    let selectedOptionalRiders = {};
    if (medicalPlans.length === 0) {
        medicalPlans = isUhcRenewals() ? fullProps.renewalShopping.plans : fullProps.quotes.plans;
    }
    if (isUhcRenewals()) {
        let uhcOptionalRiders = {};
        medicalPlans.forEach(plan => {
            if (plan.optionalRider && plan.optionalRider.length > 0) {
                uhcOptionalRiders[plan.code] = [];
                plan.optionalRider.forEach(rider => {
                    uhcOptionalRiders[plan.code].push(rider.riderCode);
                });
            }
        });
        return uhcOptionalRiders
    }
    planCodes.forEach(planCode => {
        const planIdx = medicalPlans.findIndex((plan) => plan.code === planCode.code || plan.code === planCode);
        if(planIdx >= 0) {
            selectedOptionalRiders[planCode.code || planCode] = medicalPlans[planIdx].optionalRider.map(rider => rider.riderCode);
        }
    });
    return selectedOptionalRiders;
}

const getEmployees = (employees, primaryLocationIdx, stateCode) => {
    let employeesCopy = deepCopy(employees[primaryLocationIdx] ? employees[primaryLocationIdx] : []);
    if (getMarketType() === MARKET_TYPES.UHC && stateCode === 'CA') {
        employeesCopy = employeesCopy.filter(emp => emp.employeeStatus !== '1099 Independent Contractors');
    }

    const filteredEmployees = employeesCopy?.map((employee, i) => {
        employee.employeeId = i + 1
        return employee;
    });

    return removeWaivedAllEmployees(filteredEmployees);
}

const getLocation = (locations, primaryLocationIdx) => {
    return locations[primaryLocationIdx];
}