import { DataUsageSharp } from '@material-ui/icons';
import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { history } from '../utils/history';
import { createHistory } from './historyAction';
import { getRatesDetailsFetcher, getDVLRatesDetailFetcher, getProposalSpecialtyRequest } from '../components/activities/Renewal/RenewalEnrollment/RenewalEnrollmentUtils';
import { isEmpty } from '../utils/formatters/strings';
import { shoppingRequest, getEligibleCounts } from '../components/activities/Renewal/RenewalShopping/ShoppingUtils';
import deepCopy from '../utils/deepCopy';
import { identifyPlatform } from '../components/activities/Renewal/Review/reviewUtils';
import { getMarketType, MARKET_TYPES, isUhcRenewals, isOxfordRenewals,getEligibleCountFromIbr} from '../utils/businessLogic/platformUtils';
import { updateOverridesRequestBody } from '../utils/Renewal/uhcRenewalsOverrides';
import { getTransactionResult } from './quickQuotesActions';
import { getMemberMappingFromCompanyProfile, getMembersMappingFromIBR } from '../utils/Renewal/uhcRenewalDataHelper';
import { getZipDataInFinalize, updateCompanyProfileEmployees } from './companyProfileActions';
import { sleep } from '../utils/commonUtils';
import companyProfile from '../reducers/products/companyProfileReducer';
import { getUHCRateIDs } from '../utils/Renewal/uhcRenewalRateIdHelper';
import { isUndefinedOrNullOrEmpty } from '../utils/validation/commonValidations';
import { enableFinalizeEnrollment } from './enrollmentActions';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { isCaseNotFinalized } from '../utils/Renewal/uhcRenewalCaseStatus';
import { isPlanThreeTiered, isPlanTwoTiered } from '../utils/businessLogic/isWashingtonTwoTier';
import { store } from '..';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { isCompositeRates } from '../components/shared/Plans/PlanDetails/PlanDetailsTabs/EmployeeCostHelper';
import {getPlanCodeBatches} from './fullQuotesActions'
import { enrollmentFormCompletionHandler } from '../components/activities/Enrollment/EmployerAppForms/EnrollmentFormCompletionHandler';
import { checkForHSAorHRAEnrolled, checkHsaOrHraAdd, checkForHSAEnrolledRL, checkForHRAEnrolledRL, checkHasSIC, checkVisionContributionType, checkDentalContributionType, checkIsDentalContributionTypeChanged, checkIsVisionContributionTypeChanged, checkLifeContributionType, checkIfLifePlanIsNonContributory, checkIsLifeContributionTypeChanged } from '../components/shared/ConfigurableForms/helpers/conditionCheckers';
import { defaultContributions } from '../components/activities/Renewal/RenewalsCommonComponents/contributionHelper';
import { addGeneralAgentData, addOwnerAssignmentData } from './reviewCardsAction';
const apiRoutes = require('../utils/api/apiRouteConfig');

export function getEnrollmentDetails(sourcePage, isRenewAsIs = false) {
    return (dispatch, getState) => {
        let fullProps = getState();
        let renewals = fullProps.renewals;
        const renewalData = fullProps.renewals?.renewalData
        const ibrPlans = fullProps.renewals?.renewalData?.Ibr?.quote?.planOfferingData[0]?.existingPlanPkg?.plans?.plan;
        const uhcPlatformCode = fullProps.renewals?.renewalCaseData?.uhcPlatformCode
        const platformBeforeIpps = uhcPlatformCode && uhcPlatformCode !== null && uhcPlatformCode !== '' ? ((uhcPlatformCode === 'M' || uhcPlatformCode === 'U') ? 'CIRRUS' : 'PRIME') : '';
        const installPlatform = isUhcRenewals() ? (platformBeforeIpps) : identifyPlatform(renewalData);
        const rulesEnrollmentForm = fullProps.rules?.enrollmentForm
        const stateCode = fullProps.renewals?.renewalCaseData?.situsState
        const effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate;
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
        const priorYearDataSelected = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
        const classingOpted = fullProps.life?.employeeClassing?.classingOpted || false;
        history.push('/enrollmentManager/manager');

        let getReqString;
        let memberGroupId;
        dispatch(createHistory('Enrollement Screen', 'Enrollment Manager', 'Success', {}, {}, 'RL'));
        const marketType = getMarketType();
        if (sourcePage === 'decide') {
            memberGroupId = fullProps.decide?.renewalCaseData?.memberGroupId || fullProps.renewals.rlCaseData.memberGroupId;
            getReqString = `${apiRoutes.quickQuotes}${marketType === MARKET_TYPES.UHC ? '/uhcRenewal' : ''}/decidePageData?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`;
        } else if (sourcePage === 'review' || sourcePage === 'renewalShoppingHelper') {
            const platform = fullProps.review.val.renewalQuoteId ? 'CIRRUS' : 'PRIME';
            memberGroupId = fullProps.review.val.customerNumber;
            getReqString = marketType === MARKET_TYPES.OXFORD ? `${apiRoutes.quickQuotes}/renewalReview?memberGroupId=${memberGroupId}&renewalQuoteId=${fullProps.review.val.renewalQuoteId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}&priorYearDataSelected=${priorYearDataSelected}`
            : `${apiRoutes.quickQuotes}/uhcRenewal/renewalReviewUhc?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=PRIME&isMMRCase=${fullProps.overall.source === 'mmr'}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`
        }

        axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`)
            .then((resp) => {
                if (
                    resp && resp.data &&
                    resp.data.locations &&
                    resp.data.locations[0] &&
                    resp.data.locations[0].zipData &&
                    resp.data.locations[0].zipData.zipCode &&
                    (resp.data.locations[0].zipData.countyFIPSCode ||
                    resp.data.locations[0].zipData.stateFIPSCode ||
                      resp.data.locations[0].zipData.countyName)
                  ) {
                    if(isCaseNotFinalized()){
                        dispatch({
                            type: types.UPDATE_RENEWAL_COMPANY_PROFILE,
                            payload: resp.data,
                        });
                    }
                  }
                if (resp.data && resp.data.enrollment && resp.data.enrollment.requiredDocs) {
                    dispatch({
                        type: types.LOAD_RENEWAL_UPLOADED_REQUIRED_DOCUMENTS,
                        payload: {
                            files: resp.data.enrollment.requiredDocs,
                        },
                    });
                }
                if(isUhcRenewals() && resp.data.reviewCards?.ownerAssignment?.producer.length > 0){
                    let prodArray = resp.data.reviewCards.ownerAssignment.producer
                    for(let i = 0;i<prodArray.length;i++){
                        dispatch(addOwnerAssignmentData(prodArray[i]))
                    }
                }
                if(isUhcRenewals() && resp.data.reviewCards?.ownerAssignment?.generalAgent.length > 0){
                    let gaArray = resp.data.reviewCards.ownerAssignment.generalAgent
                    for(let i = 0;i<gaArray.length;i++){
                        dispatch(addGeneralAgentData(gaArray[i]))
                    }
                }
                
                axios.get(getReqString)
                    .then(async (res) => {
                        sendLog({
                            action: 'GET_ENROLLMENT_DETAILS',
                            payload: res.data,
                        });
                        dispatch(createHistory('Enrollement Screen', 'GET ENROLLMENT DETAILS', 'Success', getReqString, res, 'RL'));
                        const response = res.data;
                        let newPlanRates;
                        let renewalDataCopy;
                        let finalResponse = res.data;
                        // TODO will remove medical hardcode later
                        const selectedProducts = [];
                        const newProducts = [];
                        const isSelectedMedical = false;
                        let isNewMedical = false;

                        // adding selectedProducts & newProducts
                        if (res.data) {
                            const platform = identifyPlatform(res.data.renewalData);
                            if(finalResponse.mdvContribution?.life?.eValue && res.data.enrolledLifePlans && res.data.enrolledLifePlans.length > 0 && checkIfLifePlanIsNonContributory(res.data.enrolledLifePlans, classingOpted)){
                                finalResponse.mdvContribution.life.eValue = 100;
                            }
                            // For selectedProducts//

                            if ((res.data.renewalData.renewAsIs && res.data.renewalData.renewAsIs === true) || isRenewAsIs) {
                                // res.data.shoppingPlans.map(plan => {
                                //     if (plan.selected === true) {
                                //         isSelectedMedical = true
                                //     }
                                // })
                                // if (isSelectedMedical === true) {
                                //     selectedProducts.push('medical')
                                // }
                                if (platform !== 'PRIME') {
                                    //When Renew As Is for Cirrus.
                                    if(res.data.renewalData.renewalPlanMaps && res.data.renewalData.renewalPlanMaps.length>0){
                                        for(const item of res.data.renewalData.renewalPlanMaps){
                                            if(selectedProducts.includes(item.productType.toLowerCase())===false){
                                                selectedProducts.push(item.productType.toLowerCase())
                                            }
                                        }
                                    }
                                }
                                if (res.data.renewalData && res.data.renewalData.Ibr) {
                                    const {planOfferingData} = res.data.renewalData.Ibr.quote;
                                    for (const planOffering of planOfferingData) {
                                        for (const existingPlan of planOffering.existingPlanPkg.plans.plan) {
                                            if (existingPlan.productLineType === 'MEDICAL') {
                                                selectedProducts.push('medical');
                                            }
                                            if (existingPlan.productLineType === 'DENTAL') {
                                                selectedProducts.push('dental');
                                            }
                                            if (existingPlan.productLineType === 'VISION') {
                                                selectedProducts.push('vision');
                                            }
                                            if (existingPlan.productLineType === 'BASIC_LIFE_EE') {
                                                selectedProducts.push('basicLife');
                                            }
                                            if (isUhcRenewals() && existingPlan.productLineType === 'LTD') {
                                                selectedProducts.push('ltd');
                                            }
                                            if (isUhcRenewals() && existingPlan.productLineType === 'STD') {
                                                selectedProducts.push('std');
                                            }
                                            if (isUhcRenewals() && existingPlan.productLineType === 'SUP_LIFE_EE') {
                                                selectedProducts.push('suppLife');
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (res.data.enrolledPlans && res.data.enrolledPlans.length > 0) {
                                    selectedProducts.push('medical');
                                }
                                if (res.data.enrolledDentalPlans && res.data.enrolledDentalPlans.length > 0) {
                                    selectedProducts.push('dental');
                                }
                                if (res.data.enrolledVisionPlans && res.data.enrolledVisionPlans.length > 0) {
                                    selectedProducts.push('vision');
                                }
                                if (res.data.enrolledLifePlans && res.data.enrolledLifePlans.length > 0) {
                                    selectedProducts.push('basicLife');
                                }
                                if (isUhcRenewals() && res.data.enrolledSTDPlans && res.data.enrolledSTDPlans.length > 0) {
                                    selectedProducts.push('std');
                                }
                                if (isUhcRenewals() && res.data.enrolledLTDPlans && res.data.enrolledLTDPlans.length > 0) {
                                    selectedProducts.push('ltd');
                                }
                                if (isUhcRenewals() && res.data.enrolledSuppLifePlans && res.data.enrolledSuppLifePlans.length > 0) {
                                    selectedProducts.push('suppLife');
                                }
                            }
                            // // For newProducts//
                            // if (res.data.shoppingPlans && res.data.shoppingPlans.length > 0) {
                            //     res.data.shoppingPlans.map((plan) => {
                            //         if (plan.renewalPlan === true || plan.renewalPlanType === true) {
                            //             // TODO
                            //             // isNewMedical = true
                            //         }else{
                            //                  isNewMedical = true
                            //         }
                            //     });
                            //     if (isNewMedical === true) {
                            //         newProducts.push('medical');
                            //     }
                            // }

                            {/* existingMedical: true when existing medical */}
                            {/* renewalData.products is null when medical is not existing */}
                            {/* renewalData.products.type is "Medical"  when medical is  existing */}
                            //const existingMedical = res.data.renewalData.products && res.data.renewalData.products.type && res.data.renewalData.products.type === 'Medical' ? true : false;
                            {/* added a dfifferent logic for uhcrenewals.Oxford change is not touched*/}
                            const existingMedical = isUhcRenewals() ? ( res.data.medical?.set && res.data.medical?.set.length > 0 ? true : false) : res.data.renewalData.products && res.data.renewalData.products.type && res.data.renewalData.products.type === 'Medical' ? true : false;
                            if(res.data.enrolledPlans && res.data.enrolledPlans.length>0 && existingMedical === false){
                                res.data.enrolledPlans.map(plan=>{
                                    if (!plan.renewalPlan && !plan.renewalPlanType && !plan.isRenewal) {
                                        if(newProducts.includes('medical')===false){
                                            newProducts.push('medical')
                                        }
                                    }
                                })
                            }
                            if (res.data.dental) {
                                if (res.data.dental.length === 0 && res.data.enrolledDentalPlans && res.data.enrolledDentalPlans.length > 0) {
                                    newProducts.push('dental');
                                } else if (res.data.dental.set && res.data.dental.set.length === 0 && res.data.enrolledDentalPlans && res.data.enrolledDentalPlans.length > 0) {
                                    newProducts.push('dental');
                                }
                            }
                            if (res.data.vision) {
                                if (res.data.vision.length === 0 && res.data.enrolledVisionPlans && res.data.enrolledVisionPlans.length > 0) {
                                    newProducts.push('vision');
                                } else if (res.data.vision.set && res.data.vision.set.length === 0 && res.data.enrolledVisionPlans && res.data.enrolledVisionPlans.length > 0) {
                                    newProducts.push('vision');
                                }
                            }
                            if (res.data.life) {
                                if (res.data.life.length === 0 && res.data.enrolledLifePlans && res.data.enrolledLifePlans.length > 0) {
                                    newProducts.push('basicLife');
                                } else if (res.data.life.set && res.data.life.set.length === 0 && res.data.enrolledLifePlans && res.data.enrolledLifePlans.length > 0) {
                                    newProducts.push('basicLife');
                                }
                            }
                            if (isUhcRenewals() && res.data.dependentLife) {
                                if (res.data.dependentLife.length === 0 && res.data.enrolledDependentLifePlans && res.data.enrolledDependentLifePlans.length > 0) {
                                    newProducts.push('depLife');
                                } else if (res.data.dependentLife.set && res.data.dependentLife.set.length === 0 && res.data.enrolledDependentLifePlans && res.data.enrolledDependentLifePlans.length > 0) {
                                    newProducts.push('depLife');
                                }
                            }

                        }

                        if (isRenewAsIs) {
                            if (res.data.renewalData && res.data.renewalData.planRates) {
                                response.renewalData.renewAsIs = true;
                                newPlanRates = res.data.renewalData.planRates.map((item) => {
                                    if (item.renewalPlanType === 'Renewal') {
                                        const updatedItem = {
                                            ...item,
                                            isChecked: true,
                                        };
                                        return updatedItem;
                                    }
                                    return item;
                                });
                                renewalDataCopy = {
                                    ...res.data.renewalData,
                                    planRates: newPlanRates,
                                };
                                finalResponse = {
                                    ...res.data,
                                    renewalData: renewalDataCopy,
                                };
                            }
                        }
                        await dispatch({
                            type: types.GET_ENROLLMENT_DETAILS,
                            payload: finalResponse,
                            selectedProducts,
                            newProducts,
                        });
                        dispatch(updateEmployeeProductSelection(resp.data.employees));
                        if (marketType === MARKET_TYPES.UHC  && resp.data.employees && ((res.data.renewalData.renewAsIs && res.data.renewalData.renewAsIs === true) || isRenewAsIs)) {
                            fullProps = getState();
                            const quotedHSAorHRA = checkForHSAorHRAEnrolled(finalResponse.enrolledPlans);
                            const hsaOrHraAdd = checkHsaOrHraAdd(finalResponse.enrolledPlans, ibrPlans);
                            const quotedHSA = checkForHSAEnrolledRL(finalResponse.enrolledPlans);
                            const quotedHRA = checkForHRAEnrolledRL(finalResponse.enrolledPlans);
                            const hasSIC = checkHasSIC(renewalData);
                            const {
                                enrolledDentalPlans,
                                enrolledVisionPlans,
                                enrolledLifePlans,
                            } = finalResponse;
                            const dentalContributionType = checkDentalContributionType(selectedProducts, enrolledDentalPlans);
                            const visionContributionType = checkVisionContributionType(selectedProducts, enrolledVisionPlans);
                            const lifeContributionType = checkLifeContributionType(selectedProducts, enrolledLifePlans);
                            const isDentalContributionTypeChanged = checkIsDentalContributionTypeChanged(renewalData, selectedProducts, enrolledDentalPlans);
                            const isVisionContributionTypeChanged = checkIsVisionContributionTypeChanged(renewalData, selectedProducts, enrolledVisionPlans);
                            const isLifeContributionTypeChanged = checkIsLifeContributionTypeChanged(renewalData, selectedProducts, enrolledLifePlans)
                            const conditionEvaluators = {
                                installPlatform,
                                selectedProducts,
                                quotedHSAorHRA,
                                hsaOrHraAdd,
                                quotedHSA,
                                quotedHRA,
                                hasSIC,
                                newProducts,
                                dentalContributionType,
                                visionContributionType,
                                lifeContributionType,
                                isDentalContributionTypeChanged,
                                isVisionContributionTypeChanged,
                                isLifeContributionTypeChanged,
                                stateCode,
                            };
                            let allEmpComplete = true;
                            for (const memberArray of resp.data.employees) {
                                for (let member of memberArray) {
                                    const localApplication = {
                                        employee: member
                                    }

                                    let inCompleteMemberData = enrollmentFormCompletionHandler('employee', localApplication, rulesEnrollmentForm, conditionEvaluators, fullProps, fullProps, "", "renewals");
                                    if (inCompleteMemberData.inCompleteSectionNames.length === 0) {
                                        member.status = 'Complete';
                                    } else {
                                        member.status = 'In Progress';
                                        allEmpComplete = false;
                                    }
                                }
                            }
                            dispatch(updateCompanyProfileEmployees(resp.data.employees));
                            if(allEmpComplete){
                                dispatch(updateEmployeesEnrollStatus('Completed'));
                            }
                        }
                        dispatch({
                            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                            payload: true,
                        });
                        await sleep(5000);
                        if(resp && resp.data) {
                            await getZipDataInFinalize(resp.data);
                        }
                        dispatch({
                            type: types.ENROLLMENT_MODIFIED_PAGE_SUCCESS,
                            payload: true,
                        });
                    })
                    .catch((err) => {
                        if(isUhcRenewals()) {
                            const reqBody = sourcePage === 'decide' ? {
                                memberGroupId, quoteTrackingNumber, priorYearDataSelected 
                            } : {
                                memberGroupId, quoteTrackingNumber, 
                                platform: 'PRIME',
                                isMMRCase: fullProps?.overall?.source === 'mmr'
                            }
                            logError(KEY_NAME, sourcePage === 'decide' ? UHC_REN_APIS.DECIDE_PAGE_DATA : UHC_REN_APIS.RENEWAL_REVIEW, 
                                'renewalsEnrollmentActions.getEnrollmentDetails', reqBody, err);
                        }
                        console.log(err);
                        dispatch(createHistory('Enrollement Screen', 'GET ENROLLMENT DETAILS', 'Error', {}, err, 'RL'));
                    });
            }).catch(err => {
                if(isUhcRenewals()) {
                    const reqBody = {
                        memberGroupId,
                        marketType, quoteTrackingNumber, priorYearDataSelected
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'renewalsEnrollmentActions.getEnrollmentDetails', reqBody, err);
                }
                throw err;
            })
        // history.push('/enrollmentManager/manager'); //TODO Will remove after testing
    };
}

export function removeEnrollmentPlan(query) {
    const data = {
        query,
    };
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_ENROLLMENT_PLAN',
            query,
        });
        dispatch({
            type: types.REMOVE_ENROLLMENT_PLAN,
            payload: data,
        });
    };
}
export function updateRenewalEnrollmentAccToDecide() {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_ENROLLEMENT_ACC_DECIDE',
        });
        dispatch({
            type: types.UPDATE_ENROLLEMENT_ACC_DECIDE,
        });
    };
}
export function updateMDVContEnroll(query) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_MDV_CONTRIBUTION_ENROLL',
            query,
        });
        dispatch({
            type: types.UPDATE_MDV_CONTRIBUTION_ENROLL,
            payload: query,
        });
    };
}

function getContributionKey(fieldName){
    switch(fieldName){
        case "medicalEmployerContribution": return "medEValue"
        case "medicalDependentContribution": return "medDValue"
        case "dentalEmployerContribution": return "denEValue"
        case "dentalDependentContribution": return "denDValue"
        case "visionEmployerContribution": return "visEValue"
        case "visionDependentContribution": return "visDValue"
        case "lifeEmployerContribution": return "lifeEValue"   
    }
}

export function updateMDVEnrollmentInitial(query) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_MDV_ENROLL',
            query,
        });
        dispatch({
            type: types.UPDATE_MDV_ENROLL,
            payload: query,
        });
    };
}

export function updateEmployeesEnrollStatus(status) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_EMPLOYEES_ENROLL_STATUS',
            status,
        });
        dispatch({
            type: types.UPDATE_EMPLOYEES_ENROLL_STATUS,
            payload: status,
        });
    };
}

export function getRatesFinalizeEnrollment(rates) {
    return (dispatch) => {
        dispatch({
            type: types.SAVE_ENROLLMENT_RATES_RENEWALS,
            payload: rates,
        });
    };
}

export function updateCaseSubmitted(status) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_CASE_SUBMITTED,
            payload: status,
        });
    };
}


export function removeEnrollmentPlanFromShopping(query) {
    const data = {
        query,
    };
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_ENROLLMENT_PLAN_SHOPPING',
            query,
        });
        dispatch({
            type: types.REMOVE_ENROLLMENT_PLAN_SHOPPING,
            payload: data,
        });
    };
}

export function addSelectedProductsToRenewalEnrollment(selectedProducts) {
    return (dispatch) => new Promise((resolve, reject) => {

            sendLog({
                action: 'ADD_SELECTED_PRODUCTS_TO_RENEWAL_ENROLLMENT',
                selectedProducts,
            });

            dispatch({
                type: types.ADD_SELECTED_PRODUCTS_TO_RENEWAL_ENROLLMENT,
                payload: selectedProducts,
            });

            resolve()
        });
}

export function addNewProductsToRenewalEnrollment(newProducts) {
    return (dispatch) => new Promise((resolve, reject) => {

            sendLog({
                action: 'ADD_NEW_PRODUCTS_TO_RENEWAL_ENROLLMENT',
                newProducts,
            });

            dispatch({
                type: types.ADD_NEW_PRODUCTS_TO_RENEWAL_ENROLLMENT,
                payload: newProducts,
            });

            resolve()
        });
}

export function updateRenewalEmployerInfoFromDB(memberGroupId, mdvContribution = {}, caseSubmitted=false, isEnroll = false) {
    return (dispatch, getState) => {
        sendLog({
            action: types.UPDATE_RENEWAL_EMPLOYER_INFO,
        });

        const fullProps = getState();
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
        const marketType = getMarketType();
        const priorYearDataSelected = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
        const effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate;
        const selectedProducts = fullProps.companyProfile?.userCardsSelectedProducts;
        const isUhcRenewal = isUhcRenewals()
        axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`)
            .then((res) => {
                const enrollmentContributions = res?.data?.employerEnrollment?.employer?.contribution;
                let { medEValue, medDValue, denEValue, denDValue, visEValue, visDValue, lifeEValue} = defaultContributions;
                // If already landed on enrollment -> update it from enrollmentContributions.
                // If landing first time -> initialize it from mdvContribution.
                // If values are coming null -> set default values from defaultContributions.
                const employerContribution = {
                    medicalEmployerContribution: !isUhcRenewal || selectedProducts?.medical ? enrollmentContributions?.medicalEmployerContribution ? enrollmentContributions.medicalEmployerContribution : mdvContribution?.medical?.eValue ? mdvContribution.medical.eValue : medEValue : '0',
                    medicalDependentContribution: !isUhcRenewal || selectedProducts?.medical ? enrollmentContributions?.medicalDependentContribution ? enrollmentContributions.medicalDependentContribution : mdvContribution?.medical?.dValue ? mdvContribution.medical.dValue : medDValue : '0',
                    dentalEmployerContribution: !isUhcRenewal || selectedProducts?.dental ? enrollmentContributions?.dentalEmployerContribution ? enrollmentContributions.dentalEmployerContribution : mdvContribution?.dental?.eValue ? mdvContribution.dental.eValue : denEValue : '0',
                    dentalDependentContribution: !isUhcRenewal || selectedProducts?.dental ? enrollmentContributions?.dentalDependentContribution ? enrollmentContributions.dentalDependentContribution : mdvContribution?.dental?.dValue ? mdvContribution.dental.dValue : denDValue : '0',
                    visionEmployerContribution: !isUhcRenewal || selectedProducts?.vision ? enrollmentContributions?.visionEmployerContribution ? enrollmentContributions.visionEmployerContribution : mdvContribution?.vision?.eValue ? mdvContribution.vision.eValue : visEValue : '0',
                    visionDependentContribution: !isUhcRenewal || selectedProducts?.vision ? enrollmentContributions?.visionDependentContribution ? enrollmentContributions.visionDependentContribution : mdvContribution?.vision?.dValue ? mdvContribution.vision.dValue : visDValue : '0',
                    basicLifeEmployerContribution: !isUhcRenewal || selectedProducts?.life ? enrollmentContributions?.basicLifeEmployerContribution ? enrollmentContributions.basicLifeEmployerContribution : mdvContribution?.life?.eValue ? mdvContribution.life.eValue : lifeEValue : '0',
                 //   lifeDependentContribution: empContribution && empContribution.lifeDependentContribution && empContribution.lifeDependentContribution !== "" ? empContribution.lifeDependentContribution : mdvContribution?.life?.dValue !== '' ? mdvContribution?.life?.dValue : '',
                }
                const standardHRA = res?.data?.employerEnrollment?.standardHRA;
                const producerInfo = res?.data?.employerEnrollment?.producer;
                dispatch({
                    type: types.UPDATE_RENEWAL_EMPLOYER_INFO,
                    payload: res.data,
                    employerContribution,
                    caseSubmitted
                });
                if (isUhcRenewals()) {
                    dispatch({
                        type: types.POPULATE_ELIGIBILITY_INFO,
                        payload: res.data,
                    });
                }
                if (isUhcRenewals() && producerInfo) {
                    dispatch({
                        type: types.POPULATE_PRODUCER_INFO,
                        payload: producerInfo,
                    });
                }
                if (isUhcRenewals() && standardHRA) {
                    dispatch({
                        type: types.POPULATE_STANDARD_HRA,
                        payload: standardHRA,
                    });
                }
            }).catch(err => {
                if(isUhcRenewals()) {
                    const reqBody = {
                        memberGroupId: memberGroupId,
                        marketType, quoteTrackingNumber, priorYearDataSelected
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'renewalsEnrollmentActions.updateRenewalEmployerInfoFromDB', reqBody, err);
                }
                throw err;
            })
    };
}

export function resetRenewalEnrollment() {
    return (dispatch) => {
        sendLog({
            action: 'RESET_RENEWAL_ENROLLMENT',
        });

        dispatch({
            type: types.RESET_RENEWAL_ENROLLMENT,
        });
    };
}

export function resetEnrollmentLoader() {
    return (dispatch) => {
        dispatch({
            type: types.ENROLLMENT_MODIFIED_PAGE_SUCCESS,
            payload: false,
        });
    };
}

export function updateNumOfAdditionalContacts(count) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_NUM_OF_ADDITIONAL_CONTACTS,
            payload: count,
        });
    };
}

export function containsNewlyAddedContacts(flag) {
    return (dispatch) => {
        dispatch({
            type: types.CONTAINS_NEWLY_ADDED_CONTACTS,
            payload: flag,
        });
    };
}

export function saveMedicalFinalizeRates(rates, enrolledPlans, product) {
    const newRates = enrolledPlans.length > 0 && enrolledPlans.map((plan) => {
        let rate;
        if (product === 'medical') {
            rate = rates.find(rate => rate.medicalPlanCode === plan.code)
        } else {
            rate = rates.find(rate => rate.code === plan.code || rate.blCode===plan.code)
        };

        let updatedPlan = {};
        if (product !== 'life') {
            updatedPlan = {
                ...plan,
                ...rate,
            };
        } else {
            updatedPlan = {
                ...plan,
                ...rate,
                byCensus: {
                    employeeRates: rate.employeeRate,
                    finalMonthlyPremium: rate.totalMonthlyPremium,
                },
            };
        }
        return updatedPlan;
    });

    return (dispatch) => {
        sendLog({
            action: 'SAVE_MEDICAL_FINALIZE_RATES',
            rates: newRates,
            product,
        });

        dispatch({
            type: types.SAVE_MEDICAL_FINALIZE_RATES,
            rates: newRates,
            product,
        });
    };
}

export function updateSICCodeToRenewalData(value) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SIC_CODE_TO_RENEWAL_DATA,
            payload: value,
        });
    };
}
export function checkIfRateAdjustmentPlans(plans) {
    for(let i = 0 ; i < plans.length ; i++){
        if(plans[i] && (plans[i].rateAdjustments || plans[i].hasOwnProperty('rateAdjustments'))){
            return true;
        }
    }
    return false;
}

const doSpecialtyRateCall = (reqBody, fullProps, enrolledPlans, product, quoteStatus, dispatch, isMMRCase) => {
    return new Promise((resolve, reject) => {
        const marketTypeUhc = isUhcRenewals();
        if (marketTypeUhc && reqBody?.employees?.length == 0) {
            if(quoteStatus === 'CloseEnrollment'){
                dispatch(enableFinalizeEnrollment(false, product));
            }
            return resolve()
        } 
        if (marketTypeUhc) {
            //if (quoteStatus !== 'Proposed' || (quoteStatus === 'Proposed' && reqBody.adjustRates)) {
                // OverRide rates
            const overRideRates = updateOverridesRequestBody(reqBody, fullProps);
            reqBody.overRideRates = overRideRates;
            //}
            if(fullProps.companyProfile.rates?.MoveToEnroll?.[`${product}Rates`])
                reqBody.quoteId= fullProps.companyProfile.rates?.MoveToEnroll?.[`${product}Rates`][0]?.quoteId;
        } else {
            reqBody.priorYearDataSelected = fullProps.renewals.priorYearDataSelected;
        }

        if(isUhcRenewals()) {
            reqBody.groupSize = reqBody.eligibleCount;
            if(enrolledPlans && enrolledPlans.length > 0 && isPlanTwoTiered(enrolledPlans[0].tierStructureId)) {
                reqBody.ratingTier = '2 Tier'
            }

            if(enrolledPlans && enrolledPlans.length > 0 && isPlanThreeTiered(enrolledPlans[0].tierStructureId)) {
                reqBody.ratingTier = '3 Tier'
            }
            dispatch({
                type: types.SERVICE[product.toUpperCase()].FETCHING_FINALIZED_QUOTE_RATES,
                product: product[0].toUpperCase() + product.slice(1)
            });
        }

        axios.post(`${apiRoutes.quickQuotes}/renewal-speciality-rates`, reqBody)
            .then((productResponse) => {
                const productRates = productResponse.data[`${product}Rates`] || [];
                dispatch(createHistory(`RENEWAL Speciality ${quoteStatus} ${product[0].toUpperCase() + product.slice(1)} Rates in Enrollement Action`, 'Renewal Enrollment', 'Success', reqBody, productRates, 'RL'));
                if (quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment') {
                    dispatch(saveMedicalFinalizeRates(productRates, enrolledPlans || [], product));
                    dispatch({
                        type: types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                        rates: [],
                        dentalRates: product === 'dental' ? productRates : [],
                        visionRates: product === 'vision' ? productRates : [],
                        lifeRates: [],
                    });
                } else if (reqBody.platform.toUpperCase() === "CIRRUS") {
                        let payload = {
                            quoteStatus: quoteStatus,
                            product: product,
                            platformRatingId: productRates[0].platformRatingID,
                            rateUrl: productRates[0].rateUrl,
                            finalRateObj: {}
                        }
                        dispatch({
                            type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                            payload
                        })
                } else if (isMMRCase) {
                    dispatch({
                        type: types.RECEIVE_MMR_OVERRIDE_RATES,
                        rates: productRates,
                        product,
                    })
                }
                // Save rate ids for UHC
                if (isUhcRenewals()) {
                    dispatch({
                        type: types.SERVICE[product.toUpperCase()].RECEIVE_FINALIZED_QUOTE_RATES,
                        product: product[0].toUpperCase() + product.slice(1)
                    });
                    const payload = {
                        quoteStatus: quoteStatus,
                        product,
                        platformRatingId: productRates[0].quoteId,
                        rateUrl: '',
                        finalRateObj: {},
                        rates: productRates,
                        isUhcRenewals: true,
                    };
                    dispatch({
                        type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                        payload
                    });
                    if(quoteStatus === 'CloseEnrollment'){
                        dispatch(enableFinalizeEnrollment(false, product));
                    }
                }
                resolve()
            })
            .catch((err) => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                if(isUhcRenewals()) {
                    dispatch({
                        type: types.SERVICE[product.toUpperCase()].ERROR_FINALIZED_QUOTE_RATES,
                        product: product[0].toUpperCase() + product.slice(1)
                    });
                    logError(KEY_NAME, UHC_REN_APIS.SPECIALITY_RATE, 'renewalsEnrollmentActions.doSpecialtyRateCall', reqBody, err);
                }
                dispatch(createHistory(`RENEWAL Speciality ${quoteStatus} ${product[0].toUpperCase() + product.slice(1)} Rates in Enrollement Action`, 'Renewal Enrollment', 'Error', reqBody, err, 'RL'));
                dispatch({
                    type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                    payload: formatPlanOrRateCallError(err, 'rate', true)
                });
                reject()
            });
    })
}

export function setTermedPlatform(payload) {
    return (dispatch) => {
        dispatch({
            type: types.SET_TERMED_PLATFORM,
            payload: payload,
        });
    }
}

export function getRenewalCensusRates(
    enrolledPlans,
    enrolledDentalPlans,
    enrolledVisionPlans,
    enrolledLifePlans,
    // TODO 4/30
    // quoteStatus = "PostEnrollPreInstall",
    quoteStatus = 'PreInstallCensusChange',
    proposalQueryData = {},
    pageType = '',
) {
    return async (dispatch, getState) => {
        const fullProps = getState();
        const proposalRatingIds = fullProps.companyProfile.rates;
        const isMMRCase = fullProps.overall.source === 'mmr';
        const marketTypeUhc = isUhcRenewals();

        /* @description - isExchange will either be true or be false for all enrolled plans, can take value from any plan.*/
        const isExchange = enrolledPlans.length>0?enrolledPlans[0].isExchange:false;

        let NyShop = false;
        if (quoteStatus === 'Proposed') {
            if (proposalQueryData && proposalQueryData.renewalCaseData && proposalQueryData.renewalCaseData.situsState === 'NY') {NyShop = true};
        }
        const platform = identifyPlatform(fullProps.renewalEnrollment.renewalData);
        const data = quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment' ? getRatesDetailsFetcher(fullProps, platform === 'PRIME' || platform === 'DUAL') : shoppingRequest(proposalQueryData, NyShop, platform === 'PRIME' || platform === 'DUAL');
        const medicalPlan = quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment' ? data[0] : enrolledPlans;
        const staticRequestInfo = quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment' ? data[1] : data;
        let reqBodyDentalSpecialty; let reqBodyLifeSpecialty; let 
reqBodyVisionSpecialty;

        if(quoteStatus === 'CloseEnrollment' && isUhcRenewals()){
            if(enrolledDentalPlans && enrolledDentalPlans.length > 0){
                dispatch(enableFinalizeEnrollment(true, 'dental'));
            }
            if(enrolledVisionPlans && enrolledVisionPlans.length > 0){
                dispatch(enableFinalizeEnrollment(true, 'vision'));
            }
            if(enrolledLifePlans && enrolledLifePlans.length > 0){
                dispatch(enableFinalizeEnrollment(true, 'life'));
            }
            if(enrolledPlans && enrolledPlans.length > 0){
                dispatch(enableFinalizeEnrollment(true, 'medical'));
            }
        }

        staticRequestInfo.employees = staticRequestInfo.employees.filter(emp => emp.employeeStatus !== '1099 Independent Contractors')

        const staticRequestString = {
            memberGroupId: staticRequestInfo.memberGroupId,
            parentId : fullProps.caseTracking.currentQuoteId,
            zipCode: staticRequestInfo.zipObj.zipCode,
            effectiveDate: staticRequestInfo.effectiveDate,
            stateCode: staticRequestInfo.zipObj.stateCode,
            countyCode: !isUndefinedOrNullOrEmpty(staticRequestInfo.zipObj.county) ? staticRequestInfo.zipObj.county : ' ',
            countyFIPSCode: !isUndefinedOrNullOrEmpty(staticRequestInfo.zipObj.countyFIPSCode) ? staticRequestInfo.zipObj.countyFIPSCode : ' ',
            includeNonMedical: staticRequestInfo.includeSpecialty,
            employeeCounts: staticRequestInfo.employeeCounts,
            employeeClass: staticRequestInfo.employeeClasses,
            employerContribution: staticRequestInfo.employerContribution,
            employees: !isEmpty(staticRequestInfo.employees) ? staticRequestInfo.employees : '',
            companyName: quoteStatus === 'CloseEnrollment' && isUhcRenewals() && fullProps.enrollment.application && fullProps.enrollment.application.employer && fullProps.enrollment.application.employer.generalInformation && fullProps.enrollment.application.employer.generalInformation.groupLegalName  ? fullProps.enrollment.application.employer.generalInformation.groupLegalName : staticRequestInfo.companyProfile.companyName,
            sicCode: !isEmpty(staticRequestInfo.companyProfile.sicCode) ? staticRequestInfo.companyProfile.sicCode : '',
            franchiseCode: !isEmpty(staticRequestInfo.companyProfile && staticRequestInfo.companyProfile.franchiseCode) ? staticRequestInfo.companyProfile.franchiseCode : '0000000',
            exchange: isExchange,
            platform: 'CIRRUS',
            medicalPlanCodes: quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment' ? medicalPlan.medicalPlanCodes : enrolledPlans.map((plan) => plan.code),
            quoteStatus,
            requestType : 'renewal',
        };

        if (marketTypeUhc) {
            staticRequestString.rateIds = getUHCRateIDs(quoteStatus, proposalRatingIds);
            staticRequestString.quoteId = fullProps.caseTracking?.currentQuoteId || '';
            staticRequestString.eligibleCount = getEligibleCounts(fullProps, staticRequestInfo.employeeCounts);
        } else {
            staticRequestString.platformRatingIDs = {
                medicalRatingId: proposalRatingIds && proposalRatingIds.Proposed && proposalRatingIds.Proposed.medicalRates && proposalRatingIds.Proposed.medicalRates[0].platformRatingID ? proposalRatingIds.Proposed.medicalRates[0].platformRatingID : null,
                dentalRatingId: proposalRatingIds && proposalRatingIds.Proposed && proposalRatingIds.Proposed.dentalRates && proposalRatingIds.Proposed.dentalRates[0].platformRatingID ? proposalRatingIds.Proposed.dentalRates[0].platformRatingID : null,
                visionRatingId: proposalRatingIds && proposalRatingIds.Proposed && proposalRatingIds.Proposed.visionRates && proposalRatingIds.Proposed.visionRates[0].platformRatingID ? proposalRatingIds.Proposed.visionRates[0].platformRatingID : null,
                lifeRatingId: proposalRatingIds && proposalRatingIds.Proposed && proposalRatingIds.Proposed.lifeRates && proposalRatingIds.Proposed.lifeRates[0].platformRatingID ? proposalRatingIds.Proposed.lifeRates[0].platformRatingID : null,
            };
        }
        if (quoteStatus === 'Proposed' || quoteStatus === 'MoveToEnroll' || quoteStatus === 'FinalizeEnrollment') {
            staticRequestString.groupSize = staticRequestInfo.groupSize;
            reqBodyDentalSpecialty = getProposalSpecialtyRequest('dental', staticRequestString, enrolledDentalPlans, proposalQueryData, fullProps.rules, fullProps.renewals, fullProps, isMMRCase);
            reqBodyVisionSpecialty = getProposalSpecialtyRequest('vision', staticRequestString, enrolledVisionPlans, proposalQueryData, fullProps.rules, fullProps.renewals, fullProps, isMMRCase);
            reqBodyLifeSpecialty = getProposalSpecialtyRequest('life', staticRequestString, enrolledLifePlans, proposalQueryData, fullProps.rules, fullProps.renewals,  fullProps,false);
        } else {
            reqBodyDentalSpecialty = getDVLRatesDetailFetcher(fullProps, 'DENTAL', staticRequestString, proposalQueryData);
            reqBodyVisionSpecialty = getDVLRatesDetailFetcher(fullProps, 'VISION', staticRequestString, proposalQueryData);
            reqBodyLifeSpecialty = getDVLRatesDetailFetcher(fullProps, 'BASICLIFE', staticRequestString, proposalQueryData);
        }
        const { dental: adjustedDental, vision: adjustedVision, life: adjustedLife } = fullProps.overall.userAdjustedRates;
        const { optumId } = fullProps.renewals.logInData[0];
        let checkIfDentalRateAdjustmentDone = checkIfRateAdjustmentPlans(enrolledDentalPlans);
        let checkIfVisionRateAdjustmentDone = checkIfRateAdjustmentPlans(enrolledVisionPlans);
        let checkIfLifeRateAdjustmentDone = checkIfRateAdjustmentPlans(enrolledLifePlans);

        if(marketTypeUhc) {
            reqBodyDentalSpecialty.custNum = fullProps.renewals?.renewalCaseData?.primeCustomerNumber || "";
            reqBodyVisionSpecialty.custNum = fullProps.renewals?.renewalCaseData?.primeCustomerNumber || "";
            reqBodyLifeSpecialty.custNum = fullProps.renewals?.renewalCaseData?.primeCustomerNumber || "";
        }
        
        if (adjustedDental || checkIfDentalRateAdjustmentDone) {
            if((marketTypeUhc && enrolledDentalPlans.length > 0) || !marketTypeUhc) {
                const { enrolledDentalPlans } = fullProps.rules.enrollment;
                reqBodyDentalSpecialty.enrolledDentalPlans = enrolledDentalPlans;
                reqBodyDentalSpecialty.optumId = optumId;
                reqBodyDentalSpecialty.adjustRates = true;
            }
        }

        if (adjustedVision || checkIfVisionRateAdjustmentDone) {
            if((marketTypeUhc && enrolledVisionPlans.length > 0) || !marketTypeUhc) {
                const { enrolledVisionPlans } = fullProps.rules.enrollment;
                reqBodyVisionSpecialty.enrolledVisionPlans = enrolledVisionPlans;
                reqBodyVisionSpecialty.optumId = optumId;
                reqBodyVisionSpecialty.adjustRates = true;
            }
        }

        // Basic Life Adjusted Rates Call
        if (adjustedLife || checkIfLifeRateAdjustmentDone) {
            if((marketTypeUhc && enrolledLifePlans.length > 0) || !marketTypeUhc) {
                const { enrolledLifePlans } = fullProps.rules.enrollment;
                reqBodyLifeSpecialty.enrolledLifePlans = enrolledLifePlans;
                reqBodyLifeSpecialty.optumId = optumId;
                reqBodyLifeSpecialty.adjustRates = true;
            }
        }

        let promiseArr = []
        /// Specialty Call
        if ((enrolledDentalPlans && enrolledDentalPlans.length > 0) || (isMMRCase && reqBodyDentalSpecialty.adjustRates)) {
            promiseArr.push(doSpecialtyRateCall(reqBodyDentalSpecialty, fullProps, enrolledDentalPlans, 'dental', quoteStatus, dispatch, isMMRCase))
        }
        if ((enrolledVisionPlans && enrolledVisionPlans.length > 0) || (isMMRCase && reqBodyVisionSpecialty.adjustRates)) {
            promiseArr.push(doSpecialtyRateCall(reqBodyVisionSpecialty, fullProps, enrolledVisionPlans, 'vision', quoteStatus, dispatch, isMMRCase))
        }
        if (enrolledLifePlans && enrolledLifePlans.length > 0) {
            if (marketTypeUhc && reqBodyLifeSpecialty?.employees?.length == 0) {
                if(quoteStatus === 'CloseEnrollment'){
                    dispatch(enableFinalizeEnrollment(false, 'life'));
                }
            } else {

            //if (isUhcRenewals()) {//(quoteStatus !== 'Proposed' || (quoteStatus === 'Proposed' && reqBodyLifeSpecialty.adjustRates))
                const overRideRates = updateOverridesRequestBody(reqBodyLifeSpecialty, fullProps);
                reqBodyLifeSpecialty.overRideRates = overRideRates;
                dispatch({
                    type: types.SERVICE.LIFE.FETCHING_FINALIZED_QUOTE_RATES,
                    product: 'Life'
                });
            //}
        if(marketTypeUhc) {
                let renewalData = fullProps.renewalEnrollment.renewalData ? fullProps.renewalEnrollment.renewalData : fullProps.decide.renewalData ? fullProps.decide.renewalData : fullProps.review.renewalData 
                reqBodyLifeSpecialty.groupSize = getEligibleCountFromIbr(renewalData)
            }
            axios.post(`${apiRoutes.quickQuotes}/basic-life-rates`, reqBodyLifeSpecialty)
                .then((lifeResponse) => {
                    const lifeRates = lifeResponse.data.lifeRates || [];
                    const {enrolledDependentLifePlans}=fullProps.rules.enrollment
                   if( enrolledDependentLifePlans.length > 0) {
                    enrolledLifePlans == enrolledLifePlans.concat(enrolledDependentLifePlans) 
                   }
                    const flatEmployees=fullProps.companyProfile.employees.flat()
                    dispatch(createHistory(`RENEWAL Speciality ${quoteStatus} Life Rates in Enrollement Action`, 'Renewal Enrollment', 'Success', reqBodyLifeSpecialty, lifeRates, 'RL'));
                    if (quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment') {
                        dispatch(saveMedicalFinalizeRates(lifeRates, enrolledLifePlans || [], 'life'));
                        dispatch({
                            type: types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                            rates: [],
                            lifeRates,
                            dentalRates: [],
                            visionRates: [],
                            flatEmployees,
                        });
                    } else if (reqBodyLifeSpecialty.platform.toUpperCase() === "CIRRUS") {
                        let payload = {
                            quoteStatus: quoteStatus,
                            product: 'life',
                            platformRatingId: lifeRates[0].platformRatingID,
                            rateUrl: lifeRates[0].rateUrl,
                            finalRateObj: {}
                        }
                        dispatch({
                            type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                            payload
                        })
                    }
                    // Save rate ids for UHC
                    if (isUhcRenewals()) {
                        const payload = {
                            quoteStatus: quoteStatus,
                            product: 'life',
                            platformRatingId: lifeRates[0].quoteId,
                            rateUrl: '',
                            finalRateObj: {},
                            rates: lifeRates,
                            isUhcRenewals: true,
                        };
                        dispatch({
                            type: types.SERVICE.LIFE.RECEIVE_FINALIZED_QUOTE_RATES,
                            product: 'Life'
                        });
                        dispatch({
                            type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                            payload
                        });
                        if(quoteStatus === 'CloseEnrollment'){
                            dispatch(enableFinalizeEnrollment(false, 'life'));
                        }
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: types.DISPLAY_ERROR_MESSAGE
                    });
                    if(isUhcRenewals()) {
                        dispatch({
                            type: types.SERVICE.LIFE.ERROR_FINALIZED_QUOTE_RATES,
                            product: 'Life'
                        });
                        logError(KEY_NAME, UHC_REN_APIS.BASIC_LIFE_RATES, 'renewalsEnrollmentActions.getRenewalCensusRates', reqBodyLifeSpecialty, err);
                    }
                    dispatch(createHistory(`RENEWAL Speciality ${quoteStatus} Life Rates in Enrollement Action`, 'Renewal Enrollment', 'Error', reqBodyLifeSpecialty, err, 'RL'));
                    dispatch({
                        type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                        payload: formatPlanOrRateCallError(err, 'rate', true)
                    });
                });
            }
        }
        if (enrolledPlans && enrolledPlans.length > 0) {
            if (isUhcRenewals()) {
                if ((quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll' && quoteStatus !== 'FinalizeEnrollment' ) || (quoteStatus === 'MoveToEnroll' && !(enrolledPlans[0].hasOwnProperty('quoteFinal')) && getState().renewalEnrollment.employeesEnrollStatus==='Completed')) {
                    const { renewalData, renewalCaseData, eligibilityCountsEntered, eligibilityCounts } = proposalQueryData;
                    const requestBody = getEnrolledMedicalRateRequestBody(renewalData, renewalCaseData , fullProps.companyProfile.employees , quoteStatus, fullProps.enrollment, staticRequestInfo.memberGroupId, eligibilityCountsEntered, eligibilityCounts, enrolledPlans);
                    
                    const isCompositeRate = isCompositeRates(enrolledPlans[0])
                    if (requestBody?.employees?.length == 0 || ((quoteStatus === 'CloseEnrollment' || quoteStatus === 'MoveToEnroll') && isCompositeRate)) {
                        if(quoteStatus === 'CloseEnrollment'){
                            dispatch(enableFinalizeEnrollment(false, 'medical'));
                        }
                        return
                    }
                    enrolledPlans.forEach(x=>{
                        if(!x.OISABit || Object.keys(x.OISABit).length === 0){
                            x.OISABit = requestBody.OISABit;
                        }
                    });
                    //on enrollment page
                    if(proposalQueryData && proposalQueryData.renewalCaseData && proposalQueryData.renewalCaseData.situsState && ['CA'].includes(proposalQueryData.renewalCaseData.situsState))
                    {
                        let hasPrimePlans = false;
                        requestBody.medicalPlanCodes = enrolledPlans.map(plan => {
                            if(plan.platform.toLowerCase() === 'prime') hasPrimePlans = true;
                            return{code : plan.code,platform :plan.platform}
                        });

                        if(!hasPrimePlans || fullProps?.renewals?.renewalCaseData?.custType === 'NICE' ) {
                            requestBody.altQuoteInd = "N";
                            requestBody.renewalRateId = ""
                        }
                    }
                    else
                    {
                        requestBody.medicalPlanCodes = enrolledPlans.map(plan => plan.code);
                    }                   
                    
                    const ratingMethod = enrolledPlans[0].defaultRatingType;

                    dispatch({
                        type: types.SERVICE.MEDICAL.FETCHING_FINALIZED_QUOTE_RATES,
                        product: 'Medical'
                    });

                    let rateBatches = []
                    if(platform !== 'CIRRUS' && proposalQueryData && proposalQueryData.renewalCaseData && proposalQueryData.renewalCaseData.situsState &&  ['MD','WV','VA','DE'].includes(proposalQueryData.renewalCaseData.situsState)){
                        let medicalPlansObj = {
                            quotes: {
                                plans: enrolledPlans
                            },
                        }
                        const batchOISABit = [];
                        let allRates = [];
                        rateBatches = getPlanCodeBatches(medicalPlansObj,requestBody.medicalPlanCodes,batchOISABit);
                        for(let i = 0; i < rateBatches.length; i++){
                            requestBody.medicalPlanCodes = rateBatches[i];
                            requestBody.OISABit = batchOISABit[i];
                            axios.post(`${apiRoutes.quickQuotes}/rates/startRates`, requestBody)
                            .then(async (res) => {
                                const results = await getTransactionResult(res.data.transactionId);
                                if (results.statusCode === 400) {
                                    throw await results;
                                }
                                const rates = results.medicalRates;
                                allRates.push(...rates);
                                dispatch({
                                    type: types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                    rates: allRates,
                                    dentalRates: [],
                                    visionRates: [],
                                    lifeRates: [],
                                    ratingMethod,
                                    isUhcRenewal: isUhcRenewals()
                                });
                                dispatch({
                                    type: types.SERVICE.MEDICAL.RECEIVE_FINALIZED_QUOTE_RATES,
                                    product: 'Medical'
                                });
                                if (quoteStatus === 'CloseEnrollment') {
                                    dispatch(enableFinalizeEnrollment(false, 'medical'));
                                }
                            })
                            .catch(err => {
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE
                                });
                                if (isUhcRenewals()) {
                                    dispatch({
                                        type: types.SERVICE.MEDICAL.ERROR_FINALIZED_QUOTE_RATES,
                                        product: 'Medical'
                                    });
                                    logError(KEY_NAME, UHC_REN_APIS.START_RATES, 'renewalsEnrollmentActions.getRenewalCensusRates', requestBody, err);
                                }
                                dispatch(createHistory(`RENEWAL Plan ${quoteStatus} Medical Rates in Enrollement Action`, 'Renewal Enrollment', 'Error', staticRequestString, err, 'RL'));
                                dispatch({
                                    type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                                    payload: formatPlanOrRateCallError(err, 'rate', false)
                                });
                            })
                        }
                    } else {
                        for (let i = 0; i < requestBody.medicalPlanCodes.length; i += 10) {
                            rateBatches.push(requestBody.medicalPlanCodes.slice(i, i + 10));
                        }
                        for(let i = 0; i < rateBatches.length; i++){
                            requestBody.medicalPlanCodes = rateBatches[i]
                            axios.post(`${apiRoutes.quickQuotes}/rates/startRates`, requestBody)
                            .then(async (res) => {
                                const results = await getTransactionResult(res.data.transactionId);
                                if (results.statusCode === 400) {
                                    throw await results;
                                }
                                const rates = results.medicalRates;
                                dispatch({
                                    type: types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                    rates,
                                    dentalRates: [],
                                    visionRates: [],
                                    lifeRates: [],
                                    ratingMethod,
                                    isUhcRenewal: isUhcRenewals()
                                });
                                dispatch({
                                    type: types.SERVICE.MEDICAL.RECEIVE_FINALIZED_QUOTE_RATES,
                                    product: 'Medical'
                                });
                                if(quoteStatus === 'CloseEnrollment'){
                                    dispatch(enableFinalizeEnrollment(false, 'medical'));
                                }
                                // TODO:: handle save rate ids
                            })
                            .catch(err => {
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE
                                });
                                if(isUhcRenewals()) {
                                    dispatch({
                                        type: types.SERVICE.MEDICAL.ERROR_FINALIZED_QUOTE_RATES,
                                        product: 'Medical'
                                    });
                                    logError(KEY_NAME, UHC_REN_APIS.START_RATES, 'renewalsEnrollmentActions.getRenewalCensusRates', requestBody, err);
                                }
                                dispatch(createHistory(`RENEWAL Plan ${quoteStatus} Medical Rates in Enrollement Action`, 'Renewal Enrollment', 'Error', staticRequestString, err, 'RL'));
                                dispatch({
                                    type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                                    payload: formatPlanOrRateCallError(err, 'rate', false)
                                });
                            })
                        }
                       
                    }
                }
            } else {
                staticRequestString.platform = 'CIRRUS';
                axios.post(`${apiRoutes.quickQuotes}/renewalPlanRates`, staticRequestString)
                    .then((res) => {
                        const rates = res.data.medicalRates;
                        dispatch(createHistory(`RENEWAL Plan ${quoteStatus} Medical Rates in Enrollement Action`, 'Renewal Enrollment', 'Success', staticRequestString, rates, 'RL'));
                        if (quoteStatus !== 'Proposed' && quoteStatus !== 'MoveToEnroll') {
                            dispatch(saveMedicalFinalizeRates(rates, enrolledPlans || [], 'medical'));
                            dispatch({
                                type: types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                rates,
                                dentalRates: [],
                                visionRates: [],
                                lifeRates: [],
                            });
                        } else if (staticRequestString.platform.toUpperCase() === "CIRRUS") {
                            let payload = {
                                quoteStatus: quoteStatus,
                                product: 'medical',
                                platformRatingId: rates[0].platformRatingID,
                                rateUrl: rates[0].rateUrl
                            }
                            dispatch({
                                type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                                payload
                            })
                        }
                    })
                    .catch((err) => {
                        dispatch(createHistory(`RENEWAL Plan ${quoteStatus} Medical Rates in Enrollement Action`, 'Renewal Enrollment', 'Error', staticRequestString, err, 'RL'));
                        dispatch({
                            type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                            payload: formatPlanOrRateCallError(err, 'rate', false)
                        });
                    });
            }
        }

        if (isMMRCase) {
            return Promise.all(promiseArr)
        }
    };
}

export function updateEmployeeProductSelection(employees) {
    return (dispatch, getState) => {
        const store = getState();
        const planTypes = [];
       // const platForm = store.overall.appConfigDetails.marketType

        const shoppingPlans = isUhcRenewals() ? [].concat(...Object.values(store.rules.enrollment.enrolledPlans)) || [] :  store.rules.enrollment.enrolledPlans.standalone || [];
        const enrolledDentalPlans = store.rules.enrollment.enrolledDentalPlans || [];
        const enrolledVisionPlans = store.rules.enrollment.enrolledVisionPlans || [];
        const enrolledLifePlans = store.rules.enrollment.enrolledLifePlans || [];
        const enrolledSTDPlans = store.rules.enrollment.enrolledSTDPlans || [];
        const enrolledLTDPlans = store.rules.enrollment.enrolledLTDPlans || [];
        const enrolledSuppLifePlans = store.rules.enrollment.enrolledSuppLifePlans || [];
        let enrolledDependentLifePlans = [];
        if(isUhcRenewals() || isOxfordRenewals())enrolledDependentLifePlans = store.rules.enrollment.enrolledDependentLifePlans || [];

        if (shoppingPlans.length > 0) planTypes.push('medical');
        if (enrolledDentalPlans.length > 0) planTypes.push('dental');
        if (enrolledVisionPlans.length > 0) planTypes.push('vision');
        if (enrolledLifePlans.length > 0) planTypes.push('life');
        if ((isUhcRenewals() || isOxfordRenewals()) && enrolledDependentLifePlans.length > 0) planTypes.push('depLife');
        if(isUhcRenewals() && enrolledSTDPlans.length > 0) planTypes.push('std')
        if(isUhcRenewals() && enrolledLTDPlans.length > 0) planTypes.push('ltd')
        if(isUhcRenewals() && enrolledSuppLifePlans.length > 0) planTypes.push('suppLife')

        const selectedEmployees = employees;
        const tempEmployees = selectedEmployees && selectedEmployees.length > 0 ? deepCopy(selectedEmployees[0]) : [];
        let doesEmplPlanRemoved = false;
        // const planTypes = ['medical', 'dental', 'life', 'vision'];

        if (
            tempEmployees !== undefined
            && tempEmployees !== null
            && tempEmployees.length > 0
        ) {
            tempEmployees.forEach((emp) => {
                let isEmp = true
                let isSuppEmp = true;
                emp && emp.productSelection.forEach((plan) => {
                    if(isUhcRenewals()){
                        const planCategory = Object.keys(plan)
                       for(let category=0; category < planCategory.length; category++){
                            if(!planTypes.includes(planCategory[category])){
                                delete plan[planCategory[category]]
                            }
                        }
                    }
                    planTypes.forEach((planType) => {
                        if(isUhcRenewals() && plan && !plan[planType]){
                            plan[planType]='select'
                        }
                        if (plan && plan[planType]) {
                            let selectedPlans = [];
                            if (planType === 'medical') {selectedPlans = shoppingPlans.length > 0 ? shoppingPlans.map((x) => x.codeDisplay) : []}
                            else if (planType === 'dental') {selectedPlans = enrolledDentalPlans.length > 0 ? enrolledDentalPlans.map((x) => x.code) : []}
                            else if (planType === 'vision') {selectedPlans = enrolledVisionPlans.length > 0 ? enrolledVisionPlans.map((x) => x.code) : []}
                            else if (planType === 'life') {
                                if(isUhcRenewals() || isOxfordRenewals()){
                                    selectedPlans = isEmp ? (enrolledLifePlans.length > 0 ? enrolledLifePlans.map((x) => x.code) : []) : enrolledDependentLifePlans.length > 0 ? enrolledDependentLifePlans.map((x) => x.code) : []
                                    isEmp=false
                                }
                                else{
                                    selectedPlans = enrolledLifePlans.length > 0 ? enrolledLifePlans.map((x) => x.code) : []
                                }
                            }
                                else if ((isUhcRenewals() || isOxfordRenewals()) && planType === 'depLife') {selectedPlans = enrolledDependentLifePlans.length > 0 ? enrolledDependentLifePlans.map((x) => x.code) : []}
                                else if ((isUhcRenewals()) && planType === 'std') {selectedPlans = enrolledSTDPlans.length > 0 ? enrolledSTDPlans.map((x) => x.code) : []}
                                else if ((isUhcRenewals()) && planType === 'ltd') {selectedPlans = enrolledLTDPlans.length > 0 ? enrolledLTDPlans.map((x) => x.code) : []}
                                else if ((isUhcRenewals()) && planType === 'suppLife') {
                                    if(isSuppEmp){
                                        selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map((x) => x.code) :[];
                                        isSuppEmp = false;
                                    } else if(plan[planType].includes('CHL')){
                                        selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map(x => x.SLChPlan?.primePlanCode) : []
                                    }else if(plan[planType].includes('SPL')){
                                        selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map(x => x.SLSpPlan?.primePlanCode) : []
                                    } else {
                                        selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map((x) => x.code) :[];
                                    }
                                }
                            if (
                                (plan[planType] && selectedPlans.length === 0)
                                || (plan[planType]
                                    && plan[planType].toLowerCase() !== 'waive'
                                    && selectedPlans && selectedPlans.length !== 0 && selectedPlans.indexOf(plan[planType]) === -1)
                            ) {
                                if(isUhcRenewals() || isOxfordRenewals()) {
                                    doesEmplPlanRemoved = true;
                                    plan[planType] = selectedPlans.length > 1 ? 'select' : selectedPlans[0];
                                    emp.status = selectedPlans.length > 1 ? 'In Progress' : 'Complete';
                                    if (emp.employeeInfo) emp.employeeInfo.status = selectedPlans.length > 1 ? 'In Progress' :  'Complete';
                                } else {
                                    doesEmplPlanRemoved = true;
                                    plan[planType] = 'select';
                                    emp.status = 'In Progress';
                                    if (emp.employeeInfo) emp.employeeInfo.status = 'In Progress';
                                }
                            }
                        }
                    });
                });
            });
        }

        if (doesEmplPlanRemoved) {
            const employees = [tempEmployees];
            if(isCaseNotFinalized()){
                dispatch({
                    type: types.UPDATE_COMPANY_PROFILE_EMPLOYEES,
                    payload: employees,
                });
            } 
            dispatch({
                type: types.UPDATE_EMPLOYEES_ENROLL_STATUS,
                payload: 'In Progress',
            });
        }
    };
}

export function updateEmployeeProductSelectionRenewalPlanElection(employees,store) {
    const planTypes = [];

    const shoppingPlans = store.rules.enrollment.enrolledPlans.standalone || [];
    const enrolledDentalPlans = store.rules.enrollment.enrolledDentalPlans || [];
    const enrolledVisionPlans = store.rules.enrollment.enrolledVisionPlans || [];
    const enrolledLifePlans = store.rules.enrollment.enrolledLifePlans || [];
    const enrolledSTDPlans = store.rules.enrollment.enrolledSTDPlans || [];
    const enrolledLTDPlans = store.rules.enrollment.enrolledLTDPlans || [];
    const enrolledSuppLifePlans = store.rules.enrollment.enrolledSuppLifePlans || [];

    if (shoppingPlans.length > 0) planTypes.push('medical');
    if (enrolledDentalPlans.length > 0) planTypes.push('dental');
    if (enrolledVisionPlans.length > 0) planTypes.push('vision');
    if (enrolledLifePlans.length > 0) planTypes.push('life');
    if(isUhcRenewals() && enrolledSTDPlans.length > 0) planTypes.push('std')
    if(isUhcRenewals() && enrolledLTDPlans.length > 0) planTypes.push('ltd');
    if(isUhcRenewals() && enrolledSuppLifePlans.length > 0) planTypes.push('suppLife')

    const selectedEmployees = employees;
    const tempEmployees = selectedEmployees && selectedEmployees.length > 0 ? deepCopy(selectedEmployees[0]) : [];
    let doesEmplPlanRemoved = false;
    // const planTypes = ['medical', 'dental', 'life', 'vision'];

    if (
        tempEmployees !== undefined
        && tempEmployees !== null
        && tempEmployees.length > 0
    ) {
        tempEmployees.forEach((emp) => {
            let isSuppEmp = true;
            emp.productSelection.forEach((plan) => {
                planTypes.forEach((planType) => {
                    if (plan && plan[planType]) {
                        let selectedPlans = [];
                        if (planType === 'medical') {selectedPlans = shoppingPlans.length > 0 ? shoppingPlans.map((x) => x.codeDisplay) : []}
                        else if (planType === 'dental') {selectedPlans = enrolledDentalPlans.length > 0 ? enrolledDentalPlans.map((x) => x.code) : []}
                        else if (planType === 'vision') {selectedPlans = enrolledVisionPlans.length > 0 ? enrolledVisionPlans.map((x) => x.code) : []}
                        else if (planType === 'life') {selectedPlans = enrolledLifePlans.length > 0 ? enrolledLifePlans.map((x) => x.code) : []}
                        else if (planType === 'std') {selectedPlans = enrolledSTDPlans.length > 0 ? enrolledSTDPlans.map((x) => x.code) : []}
                        else if (planType === 'ltd') {selectedPlans = enrolledLTDPlans.length > 0 ? enrolledLTDPlans.map((x) => x.code) : []}
                        else if ((isUhcRenewals()) && planType === 'suppLife') {
                            if(isSuppEmp){
                                selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map((x) => x.code) :[];
                                isSuppEmp = false;
                            } else if(plan[planType].includes('CHL')){
                                selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map(x => x.SLChPlan?.primePlanCode) : []
                            }else if(plan[planType].includes('SPL')){
                                selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map(x => x.SLSpPlan?.primePlanCode) : []
                            } else {
                                selectedPlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans.map((x) => x.code) :[];
                            }  
                        }
                        if (
                            (plan[planType] && selectedPlans.length === 0)
                            || (plan[planType]
                                && plan[planType].toLowerCase() !== 'waive'
                                && selectedPlans && selectedPlans.length !== 0 && selectedPlans.indexOf(plan[planType]) === -1)
                        ) {
                            doesEmplPlanRemoved = true;
                            plan[planType] = 'select';
                            emp.status = 'In Progress';
                            if (emp.employeeInfo) emp.employeeInfo.status = 'In Progress';
                        }
                    }
                });
            });
        });
    }
    return doesEmplPlanRemoved;
}

const getRenewalRateIdMedical = (enrolledPlans) => {
    const stateData = store.getState();

    if(stateData.renewalEnrollment && 
        stateData.renewalEnrollment.medical && 
        stateData.renewalEnrollment.medical.set && 
        stateData.renewalEnrollment.medical.set.length > 0) {
            if(stateData.renewalEnrollment.medical.set[0] && stateData.renewalEnrollment.medical.set[0].length > 0) {
                let medicalIbrPlan = stateData.renewalEnrollment.medical.set[0][1];

                if(enrolledPlans && enrolledPlans.length > 0) {
                    for(let i=0; i<enrolledPlans.length; i++) {
                        if(enrolledPlans[i].defaultRatingType !== enrolledPlans[i].ibrRatingType) return '';
                    }
                }

                if(medicalIbrPlan.defaultRatingType === 'composite') return medicalIbrPlan.platformCompositeRatingID;
                else return medicalIbrPlan.platformAgeBandedRatingID;
            }
    }

    return '';
}

const getGroupSizeForRateCall = (eligibilityCountsEntered, eligibilityCounts, empCount) => {
    if(eligibilityCountsEntered) {
        if(eligibilityCounts && eligibilityCounts.eligible) {
            return parseInt(eligibilityCounts.eligible);
        }
    }
    return parseInt(empCount);
}

const getRatingCountForRateCall = (eligibilityCountsEntered, eligibilityCounts, empCount) => {
    if(eligibilityCountsEntered) {
        if(eligibilityCounts && eligibilityCounts.fte) {
            return eligibilityCounts.fte.toString();
        } else if(eligibilityCounts && eligibilityCounts.atne) {
            return eligibilityCounts.atne.toString();
        }
    }
    return empCount.toString();
}

const getNiceCustomerDetails = (renewalCaseData) => {
    const stateData = store.getState();

    if(renewalCaseData && renewalCaseData.situsState === 'CA' && !renewalCaseData.hasPrimeAndNiceMedPlans && stateData.renewalEnrollment && 
        stateData.renewalEnrollment.medical && 
        stateData.renewalEnrollment.medical.set && 
        stateData.renewalEnrollment.medical.set.length > 0) {
            if(stateData.renewalEnrollment.medical.set[0] && stateData.renewalEnrollment.medical.set[0].length > 0) {
                return {
                    niceCustomerNumber: renewalCaseData.custType === 'NICE' ? stateData.renewalEnrollment.medical.set[0][1].niceCustomerNumber : "",
                    nicePolicyNumber: renewalCaseData.custType !== 'PRIME' ? stateData.renewalEnrollment.medical.set[0][1].nicePolicyNumber : ""
                }
            }
    }

    return {
        niceCustomerNumber: "",
        nicePolicyNumber: ""
    };
}

/**
 * 
 * @param  {Object} renewalData
 * @param  {Object} renewalCaseData
 * @return rate request body for enrolled medical plans
 */
const getEnrolledMedicalRateRequestBody = (renewalData, renewalCaseData , companyProfileEmployees , quoteStatus, enrollment, memberGroupId, eligibilityCountsEntered = false, eligibilityCounts = {}, enrolledPlans = []) => {
    const Ibr = renewalData.Ibr;
    const date = Ibr.quote.quoteEffDate ? Ibr.quote.quoteEffDate.year + "-" + (Ibr.quote.quoteEffDate.month < 10 ? '0' : '') + Ibr.quote.quoteEffDate.month + "-" + (Ibr.quote.quoteEffDate.day < 10 ? '0' : '') + Ibr.quote.quoteEffDate.day : '';
    const tierStructureId = Ibr?.quote?.planOfferingData && Ibr?.quote?.planOfferingData?.length > 0 && Ibr?.quote?.planOfferingData[0] && Ibr.quote.planOfferingData[0].newPlanPkg?.plans?.plan && Ibr.quote.planOfferingData[0].newPlanPkg?.plans?.plan?.length > 0 ? Ibr.quote.planOfferingData[0].newPlanPkg?.plans?.plan[0].tierStructureID : 'A'
    let employeeArray = quoteStatus === "CloseEnrollment" || quoteStatus === "PreInstallCensusChange" ? getMemberMappingFromCompanyProfile(date,companyProfileEmployees) : getMembersMappingFromIBR(renewalData.Ibr)

    let renewalRateId = getRenewalRateIdMedical(enrolledPlans);
    let niceCustomerDetails = getNiceCustomerDetails(renewalCaseData)
    let customerNumber = niceCustomerDetails.niceCustomerNumber || renewalCaseData.primeCustomerNumber;
    let policyNumber = niceCustomerDetails.nicePolicyNumber || renewalCaseData.primePolicyNumber[0];

    return {
        groupSize: getGroupSizeForRateCall(eligibilityCountsEntered, eligibilityCounts, renewalData.memberGroup.locations.employeeCounts[2].count),
        stateCode: renewalData.memberGroup.locations.location.state,
        zipCode: renewalData.memberGroup.locations.location.zipCode,
        effectiveDate: renewalData.memberGroup.renewalEffectiveDate,
        companyName: quoteStatus === 'CloseEnrollment' && isUhcRenewals() && enrollment && enrollment.application && enrollment.application.employer && enrollment.application.employer.generalInformation && enrollment.application.employer.generalInformation.groupLegalName ? enrollment.application.employer.generalInformation.groupLegalName : renewalData.memberGroup.memberGroupName,
        sicCode: renewalData.memberGroup.industryCode,
        franchiseCode: renewalData.memberGroup.franchiseCode,
        platform: "PRIME",
        selectedOptionalRiders: {},
        quoteStatus,
        memberGroupId,
        isUhcRenewals: isUhcRenewals(),
        isRenewals: true,
        ratingCount: getRatingCountForRateCall(eligibilityCountsEntered, eligibilityCounts, renewalData.memberGroup.locations.employeeCounts[0].count),
        renewalProcessedDate: renewalData.memberGroup.renewalProcessedDate,
        customerNumber: customerNumber,
        policyNumber: policyNumber,
        employees: employeeArray,
        OISABit: renewalData.memberGroup.locations.location.OISABit[0],
        altQuoteInd: !renewalRateId || renewalRateId === '' ? 'N' : 'Y',
        renewalRateId,
        tierStructureId,
        isMMRCaseCompleted: renewalCaseData.isMMRCaseCompleted,
    }
}

export function updatePremiumToggle(toggleData) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_PREMIUM_TOGGLE_VALUE_ENROLLMENT',
            toggleData,
        });
        dispatch({
            type: types.UPDATE_PREMIUM_TOGGLE_VALUE_ENROLLMENT,
            payload: toggleData,
        });
    };
}

export function updateEnrollAppEmpContribution(query){
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_ENROLL_APP_EMP_CONTRIBUTION,
            payload: query
        })
    }
}

export function updateRenewalEnrollmentPlans () {
    return (dispatch, getState) => {
        const enrollmentRules = getState().rules?.enrollment
        let enrolledPlans = []
        if(enrollmentRules?.enrolledPlans) {
            Object.keys(enrollmentRules.enrolledPlans).map((item) => {
                enrolledPlans.push(enrollmentRules.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
        dispatch({
            type: types.UPDATE_RENEWAL_ENROLLMENT_PLANS,
            payload: {
                enrolledPlans,
                enrolledDentalPlans: enrollmentRules?.enrolledDentalPlans || [],
                enrolledVisionPlans: enrollmentRules?.enrolledVisionPlans || [],
                enrolledLifePlans: enrollmentRules?.enrolledLifePlans || [],
                enrolledDependentLifePlans: enrollmentRules?.enrolledDependentLifePlans || [],
            }
        })
    }
}

export function enableCloseEnrollment () {
    return (dispatch, getState) => {
        const selectedProducts = getState().companyProfile?.selectedProducts || {}
        const products = ['medical', 'dental', 'vision', 'life']
        products.forEach(product => {
            if (selectedProducts[product]) {
                dispatch(enableFinalizeEnrollment(true, product));
            }
        })
        dispatch(updateEmployeesEnrollStatus("In Progress"));
    }
}

export function updateMembersInPlans (plans){
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_MEMBERS_IN_PLANS,
            payload: {
                enrolledPlans: plans.enrolledPlans || [],
                enrolledDentalPlans: plans.enrolledDentalPlans || [],
                enrolledVisionPlans: plans.enrolledVisionPlans || [],
                enrolledLifePlans: plans.enrolledLifePlans || [],
                enrolledDependentLifePlans: plans.enrolledDependentLifePlans || []
            }
        })
    }
}