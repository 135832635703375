import {UPDATE_CHATBOT_ACTIVATION, UPDATE_QUICK_QUOTE_CHATBOT, UPDATE_RENEWAL_CHATBOT, LOGOUT_CHATBOT } from '../../actions/actionTypes';

const initialState = {
    isNB : false,
    isChatBotActivated : false,
    lastQuery : "",
    allQueries : [],
    zipCode : "",
    date : "",
    totalEmployees : "",
    productSelections : [],
    sicCode : "",
};

export default function updateChatBot(state = initialState, action) {
    switch (action.type) {
        case UPDATE_QUICK_QUOTE_CHATBOT:
            return {
                ...state,
                lastQuery : "",
                allQueries : [],
                zipCode : action.payload.zipCode ? action.payload.zipCode : null,
                date : action.payload.date ? action.payload.date : null,
                totalEmployees : action.payload.totalEmployees ? action.payload.totalEmployees : null,
                productSelections : [],
                sicCode : action.payload.sicCode ? action.payload.sicCode : null,
            };
        case UPDATE_RENEWAL_CHATBOT:
            return {
                ...state,
                isNB : false,
                lastQuery : "",
                allQueries : [],
                memberGroupId : "",
            };
        case UPDATE_CHATBOT_ACTIVATION:
            let chatbotActive = action.payload;
            if(chatbotActive === false){
                return {
                    ...state,
                    isNB : false,
                    isChatBotActivated : chatbotActive,
                    lastQuery : "",
                    allQueries : [],
                    zipCode : "",
                    date : "",
                    totalEmployees : "",
                    productSelections : [],
                    sicCode : "",
                };
            }
            else{
                return {
                    ...state,
                    isChatBotActivated : chatbotActive
                };
            };

        case LOGOUT_CHATBOT:
            return {
                ...initialState,
            };
            
        default:
            return state;
    }
}