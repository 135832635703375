import React from 'react';
import { Button, Icon, Image, Grid, Menu, Popup, Modal, Header } from 'semantic-ui-react';
import logo from '../../../images/logo-2020.svg';
import '../shared.css';
import { connect } from 'react-redux';
import BreadCrumb from './BreadCrumb/BreadCrumb';
import { updateSource, updatePrevious, resetFlowAfterRenewals, resetToInitialState, closeErrorMessage } from '../../../actions/overallActions';
import { hardResetEnrollmentRules } from '../../../actions/enrollmentActions';
import RenewalStepper from '../RenewalsStepper/RenewalStepper';
import ReviewCards from './ReviewCards/ReviewCards';
import { updateNBDashboardActivity } from '../../../actions/caseTrackAction';
import { resetRenewals, logout, saveDataToDB, saveDataToDBOxfordRL} from '../../../actions/renewalActions';
import PcisPopup from '../ModalPopUp/PcisPopup';
import { MARKET_TYPES, getMarketType, isUhcRenewals } from '../../../utils/businessLogic/platformUtils';
import { sendClickEventDataAction } from '../../../actions/analyticsAction';
import UnsavedDataPopup from '../ModalPopUp/UnsavedDataPopup';
import { getNBCaseProgress, inSubmittedStatus } from '../../../reducers/products/helpers/companyProfileConsts';
import { updateChatBotActivation } from '../../../actions/chatBotActions';

/**
 * Header rendered atop QQ & FS pages
 */

class TopHeaderLocal extends React.Component {
    state = {
        activeIndex: 0,
        activeTools: false,
        activeActitivities: false,
        // Default active navigation should default to current path
        activeNav: this.props.pathname,
        data: this.props.renewalData.renewalCaseData ? this.props.renewalData.renewalCaseData : 'No Data Available',
        loading: true,
        displayUserCards: true,
        loggedIn: false,
        pcisPopupOpen: false,
        navName:'',
        navPath: '',
        unsavedDataPopupOpen: false,
    };

    componentDidUpdate(prevProps) {
        // Determine which navigation button should be active based on pathname
        if (prevProps.renewalData.renewalCaseData !== this.props.renewalData.renewalCaseData) {
            this.setState({ data: this.props.renewalData, loading: false });
        }
        if(prevProps.renewalData.logInData != this.props.renewalData.logInData && this.props.renewalData.logInData){
            if(this.props.renewalData.logInData){
                this.setState({loggedIn : true})
            }else{
                this.setState({loggedIn:false});
            }
        }
        if (prevProps.pathname !== this.props.pathname) {
            // Plans can be routed from different source and should be set based on that source
            if (this.props.pathname === '/plans') {
                if (this.props.overall.source === 'full') {
                    this.setState({ activeNav: '/full' });
                } else {
                    this.setState({ activeNav: '/quick-quotes' });
                }
                return;
            }
            if(this.props.pathname === '/employee' && !this.props.overall.previousPage === "quick-quotes")
            {
                this.setState({ activeNav: '/employee' });
                return;
            }
            if(this.props.pathname === '/summary' && !this.props.overall.previousPage === "quick-quotes")
            {
                this.setState({ activeNav: '/summary' });
                return;
            }
            // Employee, summary, and confirmation pages are only accessible from full shopping
            if (
                (this.props.pathname === '/employee' && !this.props.overall.previousPage === "quick-quotes") ||
                this.props.pathname === '/summary' ||
                this.props.pathname === '/confirmation' ||
                (this.props.pathname === '/owner-assignment' && (!this.props.overall.isRenewals))
            ) {
                this.setState({ activeNav: '/full' });
                return;
            }

            // Census page from QQ
            if (this.props.pathname === '/employee' && this.props.overall.previousPage === "quick-quotes") {
                this.setState({ activeNav: '/quick-quotes' });
                return;
            }

            // Saved Quote and Expired path
            if (
                this.props.pathname === '/saved-quotes' ||
                this.props.pathname === '/expired'
            ) {
                this.setState({ activeNav: '' });
                return;
            }

            // Set active navigation to current pathname for all other pages
            this.setState({ activeNav: this.props.pathname });
        }
    }

    // logo will resize based on window size
    getLogoClass = () => {
        let logoClass = 'logo';
        return (logoClass += this.props.size.smallScreen
            ? ' smallIcon'
            : ' bigIcon');
    };

    // close sidebar
    closeHamburgerMenu = () => {
        const ids = ['mobileActivitiesOptions', 'mobileToolsOptions'];

        // close all dropdown options
        ids.forEach((id) => {
            document.getElementById(id).style.visibility = 'hidden';
            document.getElementById(id).style.height = '0px';
        });
        document.getElementById('hamburgerContainer').style.width = '0px';
        document.getElementById('lightbox').style.visibility = 'hidden';
        this.setState({ activeTools: false, activeActitivities: false });
    };

    displayHamburgerMenu = () => {
        document.getElementById('hamburgerContainer').style.width = '250px';
        document.getElementById('lightbox').style.visibility = 'visible';
    };

    toggleHamburgerMenu = () => {
        const { width } = window.getComputedStyle(
            document.getElementById('hamburgerContainer')
        );
        return width < '250px'
            ? this.displayHamburgerMenu()
            : this.closeHamburgerMenu();
    };

    // open and close dropdown options in sidebar
    handleDropdownClick = (e, { name }) => {
        const id = `mobile${name}Options`;
        const element = document.getElementById(id);
        this.setState({ activeItem: null });
        if (window.getComputedStyle(element).visibility === 'hidden') {
            element.style.visibility = 'visible';
            element.style.height = 'auto';
            name === 'Tools'
                ? this.setState({ activeTools: true })
                : this.setState({ activeActitivities: true });
        } else {
            element.style.visibility = 'hidden';
            element.style.height = '0px';
            name === 'Tools'
                ? this.setState({ activeTools: false })
                : this.setState({ activeActitivities: false });
        }
    };

    // return the path to the previous page based on the pathname of the current page
    linkBack = (currentPage) => {
        switch (currentPage) {
            case '/full':
                return '/';
            case '/employee':
                return '/full';
            case '/plans':
                return this.props.overall.source === 'quick-quotes'
                    ? '/quick-quotes'
                    : '/employee';
            case '/summary':
                return '/plans';
            case '/finalize-case':
                return '/summary'
            default:
                return '/';
        }
    };

    // returns the title of the previous page based on the pathname of the current page
    lastPageName = (currentPage) => {
        switch (currentPage) {
            case '/full':
                return 'Dashboard';
            case '/employee':
                return 'Company Information';
            case '/plans':
                return this.props.overall.source === 'quick-quotes'
                    ? 'Quick Quotes'
                    : 'Employee Information';
            case '/summary':
                return 'Plan Selection';
            case '/finalize-case':
                return 'Enrollment'
            default:
                return 'Dashboard';
        }
    };

    // click handler when clicking on item in sidebar
    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name, activeDropdown: null });
        this.closeHamburgerMenu();
    };

    // Determine page title based on pathname
    determinePageTitle = (pathname) => {
        if (pathname === '/') {
            return 'Dashboard';
        }
        if (pathname === '/quick-quotes') {
            return 'Quick Quotes';
        }
        if (pathname === '/saved-quotes') {
            return 'Saved Quotes';
        }
        if (pathname === '/plans') {
            return 'Plan Selection';
        }
        if (pathname === '/full') {
            return 'Company Information';
        }
        if (pathname === '/employee') {
            return 'Census Information';
        }
        if (pathname === '/summary') {
            return 'Quote Review and Finalization';
        }
        if (pathname === '/owner-assignment') {
            return 'Owner Assignment';
        }
        if (pathname === '/enrollmanager') {
            return 'Enrollment';
        }
        if (pathname === '/finalize-case') {
            return 'Finalize Case'
        }
        if(pathname==='/home'){
            return 'Dashboard'
        }

        if(pathname === '/MaintainencePage') {
            return 'MaintainencePage'
        }
        //Renewal
        if (pathname === '/login') {
            return 'Login';
        }
        if (pathname === '/renewal-dashBoard') {
            return 'Renewal Dashboard';
        }
        if (pathname === '/reviewDetail') {
            return 'Review & Consider';
        }
        if (pathname === '/decideDetail') {
            return 'Decide';
        }
        if (pathname === '/renewalShopping') {
            return 'Renewal Shopping';
        }
        if (pathname === '/enrollmentManager') {
            return 'Enrollment';
        }
        if (pathname === '/finalize') {
            return 'Finalize Renewals';
        }
        if (pathname === '/renewal-finalize-case') {
            return 'Renewal Finalize Case';
        }
    };

    showUnsavedDataPopup = () => {
        const caseProgress = getNBCaseProgress(this.props.fullProps);
        const currentUrl = window.location.href.split('/').pop();
        if (this.state.unsavedDataPopupOpen === false
            && this.props.caseId
            && !isUhcRenewals()
            && !this.props.isRenewals
            && currentUrl !== 'new-shopping-dashboard'
            && !inSubmittedStatus(caseProgress)
        ) {
            return true;
        }
        return false;
    }

    handleLogoClickInitial = (name, path) => {
        if (isUhcRenewals() && this.props.renewalData.disableTopHeader) {
            return
        }
        this.setState({
            navName: name,
            navPath: path
        })
        // this.props.updateChatBotActivation(false)
        if (this.showUnsavedDataPopup()) {
            this.setState({ unsavedDataPopupOpen: true })
        } else {
            this.handleLogoClick(path);
        }
    }

    handleHeaderItemClick = (e, {name, path}) => {
        this.setState({
            navName:name,
            navPath:path
        })
        if (this.showUnsavedDataPopup()) {
            this.setState({unsavedDataPopupOpen:true})
        } else {
            switch(name){
                case 'Dashboard':
                case 'Quick Quotes':
                case 'Full Shopping':
                case 'Off Season Shopping':
                case 'Member Maintenance':
                case 'Admin Settings' :   
                    this.handleNavClick(path);
                    break;
                case 'Logout':
                    this.handleLogout();
                    break;
                default : 
                    break;
            }
        }
    }

    closeUnsavedPopup = () => {
        this.setState({unsavedDataPopupOpen:false})
    }

    handleLogoClick = (path) => {
        this.setState({unsavedDataPopupOpen:false})
        this.props.history.push(path);
    }

    handleNavClick = (path) => {
        this.props.sendClickEventDataAction({
            clickName : this.determinePageTitle(path),
            clickElement : 'Button',
            clickType : 'internal',
            clickLocation:"Header",
          })
        this.setState({unsavedDataPopupOpen:false})
        if (getMarketType() === MARKET_TYPES.UHC && this.props.overall.isRenewals) {
            saveDataToDB(window.location.pathname);
        }else if(getMarketType() === MARKET_TYPES.OXFORD && this.props.overall.isRenewals){
            this.props.saveDataToDBOxfordRL(window.location.pathname);
        }
        setTimeout(() => {
            if(path === '/full')
                this.props.updateNBDashboardActivity("REQUEST_NB_NEW_CASE")
        }, 1000);
        this.props.history.push(path)
        this.props.resetRenewals();
        this.props.resetFlowAfterRenewals();
        this.props.resetToInitialState()
        this.setState({ activeNav: path });
        this.props.hardResetEnrollmentRules();
        // this.props.updateChatBotActivation(false);
    };

    /**
     * Toggles whether user cards should be expanded/collapsed on Company Info/Census/Plans Page
     */
    toggleUserCards = () => {
        this.setState({ displayUserCards: !this.state.displayUserCards });
    }

    closeErrorMsg = () => {
        this.props.closeErrorMessage();
    }

    // Logs user out of application
    handleLogout = () => {
        this.setState({
            loggedIn: false
        });
        this.setState({unsavedDataPopupOpen:false})
        this.props.sendClickEventDataAction({
            clickName :'Logout',
            clickElement : 'Button',
            clickType : 'internal',
            clickLocation:"Header",
          })
        this.props.logout();
        this.props.history.push('/logout');
    }

    // Closes pcis popup
    closePcisPopup = () => {
        this.setState({ pcisPopupOpen: false });
    }

    render() {
        const {
            activeNav,
            loggedIn,
            pcisPopupOpen,
        } = this.state;

        const {
            permissions,
            username,
            workingOnBehalfOf,
            pcis,
        } = this.props;

        const savedQuoteButtonAppears = [
            '/quick-quotes',
            '/employee',
            '/plans',
            '/summary',
        ];
        const breadcrumbPages = [
            '/full',
            '/employee',
            '/plans',
            '/summary',
            '/enrollmanager',
        ];

        const EmailLink = (
            <a
                href={
                    this.props.overall.appConfigDetails.otherDetails
                        .sendFeedbackEmail
                }
            >
                <Button size="small" compact className="whiteButton marginTopExtraSmall">
                    <Icon name="mail" />
                    Send Feedback
                </Button>
            </a>
        );

        let loggedInUserText = username !== null ? username : 'Not Logged In';
        if (workingOnBehalfOf && username) {
            loggedInUserText += ` working on behalf of ${workingOnBehalfOf}`;
        }

        const loggedInDisplayText = (
            <div className="loggedInUserText marginLeftExtraSmall">
                <h4 className="blueFont">{loggedInUserText}</h4>
            </div>
        )

        const detailedInfoPopup = (
            <Popup trigger={loggedInDisplayText} disabled={!loggedIn} flowing hoverable>
                <h4 className="noMarginBottom">Working on behalf of:</h4>
                <div>{`${workingOnBehalfOf ? workingOnBehalfOf : username} (PCIS: ${pcis})`}</div>
            </Popup>
        )

        const loggedInUser = (
            <div className="alignCenter marginLeftMassive marginRightMassive">
                <Icon className="blueFont" name="user circle" size="large"/>
                {detailedInfoPopup}
            </div>
        );

        const logOutButton = (
            <Button size="small" compact className="maxHeightButton whiteButton marginTopExtraSmall marginRightLarge marginLeftExtraLarge" name='Logout' path='/logout' onClick={this.handleHeaderItemClick}>
                <Icon name="sign-out"/>
                Logout
            </Button>

        );

        // Determines if census page is active for FS, takes into account the ID within the pathname
        const splitActiveNav = activeNav.split("/")
        const fsCensusPage = splitActiveNav.length === 3 && splitActiveNav[2] === "employee";
        const {
            startANewQuoteStartsBrandNewCase,
            startANewQuickQuote,
        } = this.props.mainDashboardPermissions;

        return (
            <>
                {/* Top Level Navigation - not sticky */}
                <article className="headerMenu">
                    <section className="noPadding">
                        <Image
                            className={`${this.getLogoClass()} linkButton`}
                            src={logo}
                            disabled={isUhcRenewals() && this.props.renewalData.disableTopHeader}
                            onClick={()=>this.handleLogoClickInitial('Logo', this.state.loggedIn===true?'/home':'/')}
                        />
                    </section>
                    <section position="right">
                        <div className="navigationButtons">
                            <Menu secondary>
                            {this.props.renewalData.roleName ==="Production Support Admin"  && (   
                               <Menu.Item
                                        name = "Admin Settings"
                                       active = {activeNav === '/MaintainencePage'}
                                        path = "/MaintainencePage"
                                        onClick = {this.handleHeaderItemClick}
                                        disabled={!this.state.loggedIn || (isUhcRenewals() && this.props.renewalData.disableTopHeader) || this.props.chatbot.isChatBotActivated}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}    
                            {this.props.overall.appConfigDetails.headerMenu.dashboard && (
                                    <Menu.Item
                                        name = "Dashboard"
                                        active = {(this.state.loggedIn ? activeNav === "/" :null )|| activeNav === "/home"}
                                        path = "/home"
                                        onClick = {this.handleHeaderItemClick}
                                        disabled={!this.state.loggedIn || (isUhcRenewals() && this.props.renewalData.disableTopHeader)}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}
                                {this.props.overall.appConfigDetails.headerMenu.quickQuote && startANewQuickQuote && (
                                    <Menu.Item
                                        name="Quick Quotes"
                                        active = {activeNav === '/quick-quotes'}
                                        path = "/quick-quotes"
                                        disabled={!this.state.loggedIn || (isUhcRenewals() && this.props.renewalData.disableTopHeader) || this.props.chatbot.isChatBotActivated}
                                        onClick = {this.handleHeaderItemClick}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}
                                {this.props.overall.appConfigDetails.headerMenu.fullQuote && startANewQuoteStartsBrandNewCase && (
                                    <Menu.Item
                                        name="Full Shopping"
                                        active = {['/full', '/employee', '/plans', '/summary', '/finalize-case'].includes(activeNav) || activeNav.includes('/enrollment')}
                                        path = "/full"
                                        onClick = {activeNav !== '/full' && this.handleHeaderItemClick}
                                        disabled={!this.state.loggedIn || (isUhcRenewals() && this.props.renewalData.disableTopHeader) || this.props.chatbot.isChatBotActivated}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}
                                {/* Routes should be defined in ContentContainer, route should match name */}
                                {this.props.overall.appConfigDetails.headerMenu.offshoreSeason && (
                                    <Menu.Item
                                        name = "Off Season Shopping"
                                        active = {activeNav === 'off-season-shopping'}
                                        path = "/"
                                        onClick = {this.handleHeaderItemClick}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}
                                {this.props.overall.appConfigDetails.headerMenu.memberMaintanance && (
                                    <Menu.Item
                                        name = "Member Maintenance"
                                        active = {activeNav === "member-maintenance"}
                                        path = "/"
                                        onClick = {this.handleHeaderItemClick}
                                        className = "marginLeftSmall marginRightSmall"
                                    />
                                )}
                                {loggedInUser}
                                {workingOnBehalfOf &&
                                    <Button
                                        className="primary maxHeightButton marginRightLarge marginTopExtraSmall"
                                        onClick={() => {
                                            this.props.history.push('/home');
                                            this.setState({ pcisPopupOpen: true });
                                        }}
                                    >
                                        Change
                                    </Button>}
                                {username !== null && logOutButton}
                                {/* {this.props.overall.appConfigDetails.headerMenu.sendFeedback && EmailLink} */}
                            </Menu>
                        </div>
                    </section>
                </article>

                {/* Breadcrumb - sticky */}
                <div className="breadcrumbStickyContainer width100Percent justifyCenter">
                    {this.props.overall.source !== 'quick-quotes' && this.props.overall.source !== 'renewal'? (
                        <div className="breadCrumbContainer">
                            <BreadCrumb
                                roleName={this.props.roleName}
                                history={this.props.history}
                                pathname={this.props.pathname}
                            />
                        </div>
                    ) : null}
                    {this.props.pathname == '/reviewDetail' ? (
                        <RenewalStepper
                            history={this.props.history}
                            pathname={this.props.pathname}
                            review_consider={true}
                        />
                    ) : this.props.pathname == '/renewalShopping' ? (
                        <RenewalStepper
                            history={this.props.history}
                            pathname={this.props.pathname}
                            renewalShopping={true}
                            />
                        ) : this.props.pathname == '/decideDetail' ? (
                            <RenewalStepper
                                history={this.props.history}
                                pathname={this.props.pathname}
                                decidePage={true}
                            />
                        ) : this.props.pathname == '/enrollmentManager/manager' ? (
                            <RenewalStepper
                                history={this.props.history}
                                pathname={this.props.pathname}
                                enrollment={true}
                            />
                        ): this.props.pathname == '/enrollmentManager/finalize' ? (
                            <RenewalStepper
                                history={this.props.history}
                                pathname={this.props.pathname}
                                enrollment={true}
                            />
                        ) : this.props.pathname == '/finalize' ? (
                            <RenewalStepper
                                history={this.props.history}
                                pathname={this.props.pathname}
                                finalize={true}
                            />
                        ) : this.props.pathname == '/renewal-finalize-case' ? (
                            <RenewalStepper
                                history={this.props.history}
                                pathname={this.props.pathname}
                                renewalFinalizeCase={true}
                            />
                        ) : null
                    }
                </div>

                {(this.props.pathname.includes('/full') ||
                    fsCensusPage ||
                    (this.props.pathname.includes('/plans') && (!this.props.overall.source || this.props.overall.source !== "quick-quotes")) ||
                    this.props.pathname.includes('/summary') ||
                    this.props.pathname.includes('enrollment/manager') ||
                    this.props.pathname.includes('/reviewDetail') ||
                    this.props.pathname.includes('/decideDetail') ||
                    this.props.pathname.includes('/renewalShopping') ||
                    this.props.pathname.includes('/enrollmentManager/manager') ||
                    this.props.pathname.includes('/finalize') || 
                    this.props.pathname.includes('/finalize-case') || 
                    this.props.pathname.includes('/renewal-finalize-case') ||
                    (this.props.pathname==='/employee' && (!this.props.overall.source || this.props.overall.source !== "quick-quotes"))
                ) &&
                    <div className={`reviewCardsStickyContainer marginBottomMedium${this.props.overall.source !== 'quick-quotes' && this.props.overall.source !== 'renewal' ? "" : " renewals"}`}>
                        <ReviewCards
                            history={this.props.history}
                            pathname={this.props.pathname}
                            permissions={permissions}
                            displayUserCards={this.state.displayUserCards}
                            toggleUserCards={this.toggleUserCards}
                            top={true}
                        />
                    </div>
                }

                <PcisPopup open={pcisPopupOpen} handleClose={this.closePcisPopup} />

                <UnsavedDataPopup 
                    navName={this.state.navName} 
                    navPath={this.state.navPath} 
                    open={this.state.unsavedDataPopupOpen} 
                    handleClose={this.closeUnsavedPopup} 
                    handleLogoClick={this.handleLogoClick} 
                    handleNavClick={this.handleNavClick} 
                    handleLogout={this.handleLogout} 
                />

                {this.props.overall.displayErrorMessage && this.props.overall.displayDynamicErrorMessage && this.props.overall.displayDynamicErrorMessage?.length > 0 ? <Modal
                    closeIcon 
                    size='mini'
                    onClose={() => this.closeErrorMsg()}
                    open={this.props.overall.displayErrorMessage && this.props.overall.displayDynamicErrorMessage}
                >
                    <Header style={{color: 'red'}} icon='times circle' content='ERROR!' />
                    <Modal.Content>
                        <p>{this.props.overall.displayDynamicErrorMessage}</p>
                    </Modal.Content>
                </Modal> : 
                <Modal
                    closeIcon 
                    size='mini'
                    onClose={() => this.closeErrorMsg()}
                    open={this.props.overall.displayErrorMessage}
                >
                    <Header style={{color: 'red'}} icon='times circle' content='ERROR!' />
                    <Modal.Content>
                        <p>There is a technical issue preventing this activity at this time. Please contact us at 888-201-4216.</p>
                        <p>The SAMx Support Team is available Monday through Friday 8 a.m. - 8 p.m. EST.</p>
                    </Modal.Content>
                </Modal> }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    overall: state.overall,
    size: state.size,
    permissions: state.permissions,
    chatbot: state.chatbot,
    updateSource,
    updatePrevious,
    renewalData: state.renewals,
    username: state.renewals && state.renewals.fullName ? state.renewals.fullName
                : (state.renewals && state.renewals.logInData && state.renewals.logInData[0] ? state.renewals.logInData[0].userName : null),
    workingOnBehalfOf: state.renewals.workingOnBehalfOf,
    pcis: state.renewals.val,
    mainDashboardPermissions: state.permissions.mainDashboard,
    roleName: state.renewals.roleName,
    caseId: state.caseTracking.caseId,
    businessType: state.caseTracking.caseTrack && state.caseTracking.caseTrack[0] && state.caseTracking.caseTrack[0].businessType ? state.caseTracking.caseTrack[0].businessType : "",
    isRenewals: state.overall.isRenewals,
    fullProps: state
});

const mapDispatchToProps = {
    resetFlowAfterRenewals,
    hardResetEnrollmentRules,
    updateNBDashboardActivity,
    resetToInitialState,
    resetRenewals,
    closeErrorMessage,
    logout,
    sendClickEventDataAction,
    saveDataToDBOxfordRL,
    updateChatBotActivation
};

export default connect(mapStateToProps,mapDispatchToProps)(TopHeaderLocal);