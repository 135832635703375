import deepEqual from "deep-equal";
import { getSicObj, getZipDisplay } from "../../components/shared/FileInput/FileInputHelper";
import deepCopy from '../../utils/deepCopy';
import { isEmpty } from '../../utils/formatters/strings';
import { getReduxStoreReference } from '../templateParser/mappingHolder/mappingHelper';
import { checkSelectedProducts } from "../../components/shared/ConfigurableForms/helpers/conditionCheckers";
import moment from 'moment';
import * as XLSX from 'xlsx/xlsx.js';
const Excel = require('exceljs');

/** Function to read excel and give workbook object */
export const readExcel = (file) => {
    return new Promise((resolve, reject) => {
        const wb = new Excel.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer)
                .then((wkbk) => {
                    resolve(wkbk);
                })
                .catch(err => {
                    console.log(err);
                    console.log('Some error while reading workbook')
                    reject(err);
                })
        }
    });
}

/* Function to read the rde xlsx.

/** Function to read excel and give workbook object */
export const readXlsx = (file) => {
    console.log("reading the xlxs file",file)
    return new Promise((resolve, reject) => {
        console.log("reader in xlxs")
   //     const wb = new XLSX.r();
   const wb = new Excel.Workbook();
        const reader = new FileReader();
        console.log("reader in xlxs",file)
        console.log("reader before copying xlxs",reader)
        reader.readAsArrayBuffer(file);
        console.log("reader after copying xlxs",reader)
        reader.onload = () => {
            console.log("reader for excel xlxs",reader.result)
            const buffer = reader.result;
             console.log("reading load xlxs",buffer)
     const wkbk = XLSX.read(buffer);
     console.log("inside xlsx",wkbk);
         resolve(wkbk)
            
//            .then((wkbk) => {
//                console.log("inside the xlsx book")
//                console.log("work book",wkbk);
//                  resolve(wkbk);
//                })
//                .catch(err => {
//                    console.log(err);
//                    console.log('Some error while reading workbook')
//                    reject(err);
//                })
        }
    });
}





/* Function to check if the excel uploaded is correct or not.*/
export const checkIfMarketTypeValid = (marketTypeWorkBook, versionHistory) => {
    if (marketTypeWorkBook === undefined || marketTypeWorkBook === null || versionHistory === undefined || versionHistory === null) {
        return {
            marketTypeValid: false,
            error: new Error('Invalid Workbook')
        }

    } else {
        const marketTypeValue = marketTypeWorkBook.getCell('A2').value;
        const version = versionHistory.getCell('A6').value;
        if (marketTypeValue !== 'UHC' || version !== 'v4DD') {
            return {
                marketTypeValid: false,
                error: new Error('Invalid Market Type')
            }
        }
    }

    return {
        marketTypeValid: true,
        error: null
    }
}

/*  Function to check file type. If incorrect return false */
export const checkIfInvalidFileType = (fileName) => {
    const filetype = fileName.split('.')[fileName.split('.').length - 1];
    return filetype !== 'xlsx' ? true : false;
}

export const checkIfvalidFileTypeSubmittedCensus = (fileName) => {
    const filetype = fileName.split('.')[fileName.split('.').length - 1];
    return filetype !== 'xlsm' ? true : false;
}

/** Check if worksheet uploaded is valid for UHC market */
export const checkIfValidWorkSheet = (wkbk) => {
    const marketTypeWorkBook = wkbk.getWorksheet('Market Type');
    const versionHistory = wkbk.getWorksheet('Version History');
    const validationResult = checkIfMarketTypeValid(marketTypeWorkBook, versionHistory);
    return validationResult.marketTypeValid;
}

/***  Check if worksheet uploaded is valid having New enrollment form and tabs */
export const checkIfValidWorkSheetSubmittedCensus = (wkbk) => {
   // const workSheet = wkbk.getWorksheet('NEW Enrollment Form');
     const workSheet = wkbk.Sheets['NEW Enrollment Form']; 
    const hasWorkSheet = workSheet && workSheet != null ? true : false;
    return hasWorkSheet;
}

export const checkIfValidToolsSheetSubmittedCensus = (wkbk) => {
   // const workSheet = wkbk.getWorksheet('Tools');
      const workSheet = wkbk.Sheets['Tools'];
    const hasWorkSheet = workSheet && workSheet != null ? true : false;
    return hasWorkSheet;
}

export const checkIfValidVersionSubmittedCensus = (wkbk, censusVersion) => {
    //const toolVersionChk = wkbk.getWorksheet('Tools').getCell('A1').value.includes(censusVersion);
    const ws = wkbk.Sheets['Tools'];
    let  toolValue = Object.values(ws['A1']);
    const toolVersionChk = toolValue[1].includes(censusVersion);
    return toolVersionChk;
}

export const getSicDataObj = async (sicCode) => {
    //const sicCodeRegex = /^\d{4}+$/;
    let sicData = {};
    let sicDataError = false;
    let sicDataErrorDesp = [];

    if (!isEmpty(sicCode)) { // TODO: Check if sic match numeric and 4 digit
        const sicObj = await getSicObj(sicCode);
        if (isEmpty(sicObj)) {
            sicDataError = true;
            sicDataErrorDesp = [{ error: 'SIC Code is Invalid.' }];
        } else {
            sicData = sicObj;
        }
    } else {
        sicDataError = true;
        sicDataErrorDesp = [{ error: 'SIC Code is Invalid.' }];
    }

    return {
        sicData,
        sicDataError,
        sicDataErrorDesp,
    }
}

export const getZipDataObj = async (zipCode) => {

    let zipDisplay = {};
    let zipCodeError = false;
    let zipCodeErrorDesp = [];
    if (!isEmpty(zipCode)) { // TODO: Check is valid zipcode through regex.
        const zipDisplayObj = await getZipDisplay(zipCode);
        if (isEmpty(zipDisplayObj)) {
            zipCodeError = true;
            zipCodeErrorDesp = [{ error: 'Zip Code is invalid.' }];
        } else {
            zipDisplay = zipDisplayObj;
        }
    } else {
        zipCodeError = true;
        zipCodeErrorDesp = [{ error: 'Zip Code is invalid.' }];
    }
    return {
        zipDisplay,
        zipCodeError,
        zipCodeErrorDesp
    }

}

/* Format company info object returned from parser into new object with added metadata suitable for redux */
export const formatCompanyInfo = async (companyInfoObj, argSicData = {}) => {
    try {

        let updatedObject = deepCopy(companyInfoObj);

        let isError = false;
        let errors = [];

        // Check in arguments if sic data was provided or not else make api call
        let sicObj = {};
        if (Object.keys(argSicData).length > 0) {
            sicObj = argSicData;
        } else {
            const { sicData, sicDataError, sicDataErrorDesp } = await getSicDataObj(companyInfoObj.sicCode);
            if (sicDataError) {
                isError = true;
                errors = sicDataErrorDesp;
                updatedObject.sicCode = ''
            } else {
                sicObj = sicData
            }
        }

        // Format date and create date object  
        let yearMonthDay = ''
        let dateObj = {};
        if (!isEmpty(companyInfoObj.effectiveDate)) {
            const effectiveDate = new Date(companyInfoObj.effectiveDate);
            const month = effectiveDate.getMonth() + 1 < 10 ? `0${effectiveDate.getMonth() + 1}` : `${effectiveDate.getMonth() + 1}`;
            const day = effectiveDate.getDate() < 10 ? `0${effectiveDate.getDate()}` : `${effectiveDate.getDate()}`;
            const year = effectiveDate.getFullYear();
            yearMonthDay = `${year}-${month}-${day}`;
            const monthDayYear = `${month}/${day}/${year}`;
            dateObj = {
                value: effectiveDate,
                month,
                year,
                day,
                yearMonthDay,
                monthDayYear,
            };
        }

        return {
            data: {
                ...updatedObject,
                effectiveDate: yearMonthDay,
                date: dateObj,
                sicData: sicObj
            },
            metaData: {
                isError,
                errors
            }
        }

    } catch (err) {
        console.log(err);
        console.log('Error occured while parsing company info object')
    }
}

export const formatLocationInfo = async (locationInfoObj, argZipDisplay = {}) => {
    try {
        let locationCopy = deepCopy(locationInfoObj);
        let primaryLocation = locationCopy.locations[0];
        let errors = [];
        let isError = false;

        if (Object.keys(argZipDisplay).length > 0) {
            primaryLocation.zipData = argZipDisplay;
        } else {
            let { zipDisplay, zipCodeError, zipCodeErrorDesp } = await getZipDataObj(primaryLocation.zipCode);
            if (zipCodeError) {
                errors = zipCodeErrorDesp;
                isError = true;
                primaryLocation.zipData = {};
                primaryLocation.zipCode = '';
            } else {
                primaryLocation.zipData = zipDisplay;
            }
        }

        locationCopy.locations[0] = primaryLocation;
        return {
            data: locationCopy,
            metaData: {
                isError,
                errors,
            }
        }

    } catch (err) {
        console.log(err);
        console.log('Error occured while parsing location info object');
    }
}

/** Function to create company profile object from location info, employees, and companyInfo  */
export const formCompanyProfileObject = (companyInfo, locationInfo, employees, sicData = {}, zipData = {}) => {
    return new Promise((resolve, reject) => {

        let promises = [];

        promises.push(formatCompanyInfo(companyInfo, sicData));
        promises.push(formatLocationInfo(locationInfo, zipData))
        Promise.all(promises).
            then(responses => {
                let companyInfoObject = responses[0];
                let locationInfoObject = responses[1];
                resolve({
                    ...companyInfoObject.data,
                    ...locationInfoObject.data,
                    employees: [employees],
                    metaData: {
                        isError: companyInfoObject.metaData.isError || locationInfoObject.metaData.isError,
                        errors: [...companyInfoObject.metaData.errors, ...locationInfoObject.metaData.errors]
                    }
                });
            }).catch(err => {
                console.log(err);
                reject(err);
            })
    })
}


/* Function to check if company profile data uploaded is different. */
// TODO:: Testing of this function.
export const checkDiffInCompanyProfileData = (companyInfo, locationInfo, cp, isLocationInfoFilled = false, isCompanyInfoFilled = false) => {

    const { companyName, effectiveDate, franchiseCode, primaryContact, selectedProducts, sicCode } = companyInfo;
    const { atne, fte, totalEligEmps, totalEmps, zipCode } = locationInfo.locations[0];
    let isDiff = false

    let cpPrimaryContact = deepCopy(cp.primaryContact);
    delete cpPrimaryContact.fullName;

    let selectedProductsFilled = false;
    Object.keys(selectedProducts).forEach(product => {
        if (selectedProducts[product]) {
            selectedProductsFilled = true;
        }
        if (cp.selectedProducts.suppLife === true){
            selectedProducts.suppLife = true
        } 
        if (cp.selectedProducts.suppLife === false){
            selectedProducts.suppLife = false
        }
    })

    let companyInformationFilled = isCompanyInfoFilled || selectedProductsFilled;

    if (companyInformationFilled) {
        if (cp.companyName != companyName ||
            cp.franchiseCode != franchiseCode ||
            !deepEqual(cpPrimaryContact, primaryContact) ||
            !deepEqual(cp.selectedProducts, selectedProducts) ||
            cp.sicCode != sicCode

        ) {
            isDiff = true;
        }

        // const effDate = new Date(effectiveDate);
        // const cpEffDate = new Date(cp.effectiveDate);
        // if (effDate.getDate() !== cpEffDate.getDate() ||
        //     effDate.getMonth() !== cpEffDate.getMonth() ||
        //     effDate.getFullYear() !== cpEffDate.getFullYear()
        // ) {
        //     isDiff = true;
        // }
        const effDate = moment(effectiveDate).format("MM/DD/YYYY")
        const cpEffDate = moment(cp.effectiveDate).format("MM/DD/YYYY")
        if (effDate !== cpEffDate) {
            isDiff = true;
        }
    }

    if (isLocationInfoFilled) {
        const { atne: cpAtne, fte: cpFte, totalEligEmps: cpTotalEligEmps, totalEmps: cpTotalEmps, zipData: { zipCode: cpZipCode } } = cp.locations[0];
        if (atne != cpAtne ||
            fte != cpFte ||
            totalEligEmps != cpTotalEligEmps ||
            totalEmps != cpTotalEmps ||
            zipCode != cpZipCode) {
            isDiff = true;
        }
    }
    return isDiff;
}


/** Combine emp info and enrollment info dependents 
 * Whatever data is commmon is kept from employee info and uncommon data is taken from enrollment info */

const combineDependentInfo = (enrollDependents, empInfoDependents) => {

    let updatedDependents = [];
    for (let i = 0; i < empInfoDependents.length; i++) {
        const empInfoDep = empInfoDependents[i];
        const enrollInfoDep = enrollDependents[i];

        const newDep = {
            ...enrollInfoDep,
            dob: empInfoDep.dob,
            age: empInfoDep.age,
            dobInput: empInfoDep.dobInput,
            gender: empInfoDep.gender,
            relationship: empInfoDep.relationship,
            firstName: empInfoDep.firstName,
            lastName: empInfoDep.lastName,
            employeeStatus: empInfoDep.employeeStatus,
            suffix: empInfoDep.suffix,
            middleInitial: empInfoDep.middleInitial
        }

        updatedDependents.push(newDep);
    }
    return updatedDependents;
}


// Update Coverage If is Waiving All is true.
const updateCoverages = (empInfoEmp, isWaivingAll) => {
    if (isWaivingAll === 'Yes') {
        Object.keys(empInfoEmp.coverages).forEach(key => {
            empInfoEmp.coverages[key] = 'W'
        })
    }
}

/** Combine emp info and enrollment info. 
 * Whatever data is commmon is kept from employee info and uncommon data is taken from enrollment info */

export const combineEnrollmentAndEmployeeInfo = (enrollmentInfo, employeeInfo, selectedProducts) => {

    let updatesEmployees = [];

    for (let i = 0; i < employeeInfo.length; i++) {

        const empInfoEmp = employeeInfo[i];
        const enrollInfoEmp = enrollmentInfo[i];

        const isWaivingAll = enrollInfoEmp.waiveCoverage.isWaivingAll;

        // Get Updated Coverages
        updateCoverages(empInfoEmp, isWaivingAll);

        // Get updated product selection;
        updateProductSelection(enrollInfoEmp.productSelection, selectedProducts, empInfoEmp.coverages);

        const newEmp = {
            ...empInfoEmp,
            medicalCoverageInfo: {
                ...empInfoEmp.medicalCoverageInfo,
                medicalWaived: isWaivingAll === "Yes" ? true : empInfoEmp.medicalCoverageInfo.medicalWaived
            },
            pcpAssignment: enrollInfoEmp.pcpAssignment,
            productSelection: enrollInfoEmp.productSelection,
            waiveCoverage: {
                medicalWaiverReason: empInfoEmp.medicalCoverageInfo.medicalWaiverReason,
                isWaivingAll: enrollInfoEmp.waiveCoverage.isWaivingAll
            },
            waiveOption: enrollInfoEmp.waiveOption,
            waiveReason: empInfoEmp.medicalCoverageInfo.medicalWaiverReason,
            dependents: combineDependentInfo(enrollInfoEmp.dependents, empInfoEmp.dependents),
            contactInfo: {
                ...enrollInfoEmp.contactInfo,
                outOfArea: empInfoEmp.outOfArea
            },
            employeeInfo: {
                ...enrollInfoEmp.employeeInfo,
                dob: empInfoEmp.dob,
                firstName: empInfoEmp.firstName,
                lastName: empInfoEmp.lastName,
                gender: empInfoEmp.gender,
                employeeStatus: empInfoEmp.employeeStatus,
                salary: empInfoEmp.salary,
                status: empInfoEmp.status,
                suffix: empInfoEmp.suffix,
                middleInitial: empInfoEmp?.employeeInfo?.middleInitial || ''
            }
        }
        updatesEmployees.push(newEmp);
    }
    return updatesEmployees;
}

/** Update product selection based on products selected or plans enrolled by user as he progresses in application**/
const updateProductSelection = (productSelection, selectedProducts, coverages) => {
    if (selectedProducts.includes('basicLife')) {
        selectedProducts.push('life');
    }
    productSelection.forEach((products, productSelectionIndex) => {
        Object.keys(products).forEach(product => {
            if (!selectedProducts.includes(product)) {
                delete productSelection[productSelectionIndex][product]
            } else if (products[product] === '') {
                productSelection[productSelectionIndex][product] = 'select'
            }
        })
    })


    // If is waiving all case then the waive is not updated. Making the waive option on product selection.
    const waiveOptionMap = {
        medical: 'medical',
        dental: 'dental',
        vision: 'vision',
        basicLife: 'life'
    }
    if (productSelection.length > 0 && productSelection[0]) {

        Object.keys(coverages).forEach(key => {
            if (coverages[key] === 'W') {
                if (waiveOptionMap[key]) {
                    productSelection[0][waiveOptionMap[key]] = 'waive'
                }
            }
        })
    }

}

export const getSelectedProductsArray = (selectedProducts) => {
    let sp = [];
    Object.keys(selectedProducts).forEach(key => {
        if (selectedProducts[key]) {
            sp.push(key);
        }
    })
    return sp;
}

export const getEnrolledProducts = () => {
    const store = getReduxStoreReference().getState();
    const { overall, rules: { enrollment } } = store;
    return checkSelectedProducts(overall, enrollment);
}

export const getCovTypeProducts = (selectedProducts) => {
    const eligibleProductsList = ['medical', 'dental', 'vision', 'life', 'deplife', 'ltd', 'std', 'suppLife'];
    let prods = "";
    for (const key in selectedProducts) {
        if (eligibleProductsList.includes(key) && selectedProducts[key] === true) {
            if (key === 'deplife')
                prods += prods !== "" ? " | " + 'DEPENDENT LIFE' : 'DEPENDENT LIFE';
            else
                prods += prods !== "" ? " | " + key.toUpperCase() : key.toUpperCase();
        }
    }
    return prods;
}

export const extractAdobeData = (medicalPlans, dentalPlans, visionPlans, lifePlans, dependentLifePlans, stdPlans, ltdPlans, sulPlans) => {
    let planNames = ""
    let coverageType = ""
    let productType = ""
    let planFilter = ""
    let planCodes = ""

    if (medicalPlans && medicalPlans.length > 0) {
        planNames += "Medical"
        coverageType += "Medical"
        productType += "Medical"
        planFilter += "Medical"
        planCodes += "Medical"
        medicalPlans.map(medPlan => {
            planNames += " : " + medPlan.codeDisplay + " "
            coverageType += " : " + medPlan.metal + " "
            productType += " : " + (medPlan.medicalProductType ? medPlan.medicalProductType : medPlan.medicalPlanType) + " "
            planFilter += " : " + (medPlan.isPreferredPlan || medPlan.preferredPlan ? ' Preferred' : ' Standard') + " "
            planCodes += " : " + medPlan.code + " "
        })
    }
    if (dentalPlans && dentalPlans.length > 0) {
        if (planNames !== "") {
            planNames += "| Dental"
            coverageType += "| Dental"
            productType += "| Dental"
            planFilter += "| Dental"
            planCodes += "| Dental"
        }

        else {
            planNames += "Dental"
            coverageType += "Dental"
            productType += "Dental"
            planFilter += "Dental"
            planCodes += "Dental"
        }
        dentalPlans.map(dentalPlan => {
            planNames += " : " + dentalPlan.code + " "
            planCodes += " : " + dentalPlan.code + " "
            productType += " : " + dentalPlan.productType + " "
            planFilter += " : " + (dentalPlan.isPreferredPlan || dentalPlan.preferredPlan ? ' Preferred' : ' Standard') + " "

        })
    }
    if (visionPlans && visionPlans.length > 0) {
        if (planNames !== "") {
            planNames += "| Vision"
            coverageType += "| Vision"
            productType += "| Vision"
            planFilter += "| Vision"
            planCodes += "| Vision"
        }
        else {
            planNames += "Vision"
            coverageType += "Vision"
            productType += "Vision"
            planFilter += "Vision"
            planCodes += "Vision"
        }
        visionPlans.map(visionPlan => {
            planNames += " : " + visionPlan.code + " "
            planCodes += " : " + visionPlan.code + " "
            productType += " : PPO "
            planFilter += " : " + (visionPlan.isPreferredPlan || visionPlan.preferredPlan ? ' Preferred' : ' Standard') + " "

        })
    }
    if (lifePlans && lifePlans.length > 0) {
        if (planNames !== "") {
            planNames += "| Life"
            coverageType += "| Life"
            productType += "| Life"
            planFilter += "| Life"
            planCodes += "| Life"
        }
        else {
            planNames += "Life"
            coverageType += "Life"
            productType += "Life"
            planFilter += "Life"
            planCodes += "Life"
        }
        lifePlans.map(lifePlan => {
            planNames += " : " + lifePlan.code + " "
            planCodes += " : " + lifePlan.code + " "
            productType += " : " + (lifePlan.productType ? lifePlan.productType : "") + " "
            planFilter += " : " + (lifePlan.isPreferredPlan || lifePlan.preferredPlan ? ' Preferred' : ' Standard') + " "
        })
    }
    if (dependentLifePlans && dependentLifePlans.length > 0) {
        if (planNames !== "") {
            planNames += "| Dependent Life"
            coverageType += "| Dependent Life"
            productType += "| Dependent Life"
            planFilter += "| Dependent Life"
            planCodes += "| Dependent Life"
        }
        else {
            planNames += "Dependent Life"
            coverageType += "Dependent Life"
            productType += "Dependent Life"
            planFilter += "Dependent Life"
            planCodes += "Dependent Life"
        }
        dependentLifePlans.map(deplifePlan => {
            planNames += " : " + deplifePlan.code + " "
            planCodes += " : " + deplifePlan.code + " "
            productType += " : " + (deplifePlan.productType ? deplifePlan.productType : "") + " "
            planFilter += " : " + (deplifePlan.isPreferredPlan || deplifePlan.preferredPlan ? ' Preferred' : ' Standard') + " "
        })
    }
    if (stdPlans && stdPlans.length > 0) {
        if (planNames !== "") {
            planNames += "| STD"
            coverageType += "| STD"
            productType += "| STD"
            planFilter += "| STD"
            planCodes += "| STD"
        }
        else {
            planNames += "STD"
            coverageType += "STD"
            productType += "STD"
            planFilter += "STD"
            planCodes += "STD"
        }
        stdPlans.map(stdPlan => {
            planNames += " : " + stdPlan.code + " "
            planCodes += " : " + stdPlan.code + " "
            productType += " : " + (stdPlan.productType ? stdPlan.productType : "") + " "
            planFilter += " : " + (stdPlan.isPreferredPlan || stdPlan.preferredPlan ? ' Preferred' : ' Standard') + " "
        })
    }
    if (ltdPlans && ltdPlans.length > 0) {
        if (planNames !== "") {
            planNames += "| LTD"
            coverageType += "| LTD"
            productType += "| LTD"
            planFilter += "| LTD"
            planCodes += "| LTD"
        }
        else {
            planNames += "LTD"
            coverageType += "LTD"
            productType += "LTD"
            planFilter += "LTD"
            planCodes += "LTD"
        }
        ltdPlans.map(ltdPlan => {
            planNames += " : " + ltdPlan.code + " "
            planCodes += " : " + ltdPlan.code + " "
            productType += " : " + (ltdPlan.productType ? ltdPlan.productType : "") + " "
            planFilter += " : " + (ltdPlan.isPreferredPlan || ltdPlan.preferredPlan ? ' Preferred' : ' Standard') + " "
        })
    }
    if (sulPlans && sulPlans.length > 0) {
        if (planNames !== "") {
            planNames += "| SUL"
            coverageType += "| SUL"
            productType += "| SUL"
            planFilter += "| SUL"
            planCodes += "| SUL"
        }
        else {
            planNames += "SUL"
            coverageType += "SUL"
            productType += "SUL"
            planFilter += "SUL"
            planCodes += "SUL"
        }
        sulPlans.map(sulPlan => {
            planNames += " : " + sulPlan.code + " "
            planCodes += " : " + sulPlan.code + " "
            productType += " : " + (sulPlan.productType ? sulPlan.productType : "") + " "
            planFilter += " : " + (sulPlan.isPreferredPlan || sulPlan.preferredPlan ? ' Preferred' : ' Standard') + " "
        })
    }
    return { coverageType, planNames, productType, planFilter, planCodes }

}

export const extractNewPlans = (medicalPlans, dentalPlans, visionPlans, lifePlans, dependentLifePlans, stdPlans, ltdPlans) => {
    let newMedicalPlans = [], newDentalPlans = [], newVisionPlans = [], newLifePlans = [], newDependentLifePlans = [], newStdPlans=[], newLtdPlans=[], newSulPlans=[]

    if (medicalPlans && medicalPlans.length > 0) {
        medicalPlans.map(plan => {

            if (!plan.renewalPlan) {
                newMedicalPlans.push(plan)
            }
        })
    }
    if (dentalPlans && dentalPlans.length > 0) {
        dentalPlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan) {
                newDentalPlans.push(plan)
            }

        })
    }
    if (visionPlans && visionPlans.length > 0) {
        visionPlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan) {
                newVisionPlans.push(plan)
            }

        })
    }
    if (lifePlans && lifePlans.length > 0) {
        lifePlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan)
                newLifePlans.push(plan)
        })
    }

    if (dependentLifePlans && dependentLifePlans.length > 0) {
        dependentLifePlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan)
                newDependentLifePlans.push(plan)
        })
    }
    if (stdPlans && stdPlans.length > 0) {
        stdPlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan)
                newStdPlans.push(plan)
        })
    }
    if (ltdPlans && ltdPlans.length > 0) {
        ltdPlans.map(plan => {
            if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan)
                newLtdPlans.push(plan)
        })
    }
    // if (sulPlans && sulPlansPlans.length > 0) {
    //     sulPlans.map(plan => {
    //         if (!(plan.renewalPlanType && plan.renewalPlanType == "renewing") && !plan.renewalPlan)
    //             newSulPlans.push(plan)
    //     })
    // }
    return { newMedicalPlans, newDentalPlans, newVisionPlans, newLifePlans, newDependentLifePlans, newStdPlans, newLtdPlans }
}

export const checkIdenticalPlans = (renewingPlanMaps, enrolledPlans) => {
    let identicalPlans = false
    let flag = true

    enrolledPlans.forEach((enrollingPlan) => {
        identicalPlans = false
        renewingPlanMaps.forEach((renewing, index) => {
            if ((enrollingPlan.renewalPlan && enrollingPlan.code === renewing.renewalPlanCode)) {
                identicalPlans = true
            }
            if (!identicalPlans && (index === renewingPlanMaps.length-1)) {
                flag = false
            }
        })
    })
    return flag
}
