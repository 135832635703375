import { getMarketType, MARKET_TYPES } from '../utils/businessLogic/platformUtils';
import { UPDATE_QUICK_QUOTE_CHATBOT, UPDATE_RENEWAL_CHATBOT, UPDATE_CHATBOT_ACTIVATION } from './actionTypes';

export const updateChat = (payload) => {
    return (dispatch) => {
        if(payload.isNB){
            dispatch({
                type: UPDATE_QUICK_QUOTE_CHATBOT,
                payload : payload
            });
        }else{
            dispatch({ 
                type: UPDATE_RENEWAL_CHATBOT,
                payload : {
                    isNB : true,
                    isChatBotActivated : true,
                    lastQuery : "",
                    allQueries : [],
                    zipCode : "",
                    date : "",
                    totalEmployees : "",
                    productSelections : [],
                    sicCode : "",
                }
            })
        }

    }
}

export const updateChatBotActivation = (val) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_CHATBOT_ACTIVATION',
            payload: val
        })
    }
}

export function clearChatbotData() {
    return (dispatch) => {
        dispatch({
            type: 'LOGOUT_CHATBOT'
        })
    }
}


export default updateChat;